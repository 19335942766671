import { api } from "../api";
import { parsePatientPaginatedList } from "../../parsers/patient/parsePatientPaginatedList";
import { PatientPaginatedList } from "../../model/patient/PatientPaginatedList";
import { PatientSearchFormValue } from "../../model/patient/PatientSearchFormValue";

export const getAllPatients = async (
  currentPage: number,
  pageSize: number,
  searchValue?: PatientSearchFormValue
): Promise<PatientPaginatedList> => {
  const response = await api.services.patient.getPatients(
    currentPage,
    pageSize,
    searchValue?.personalNumber?.length ? searchValue.personalNumber : undefined,
    searchValue?.fullName?.length ? searchValue.fullName : undefined
  );
  return parsePatientPaginatedList(response.data);
};
