import { AbstractDialog } from "../../../ui/dialogs/AbstractDialog";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

interface Props {
  open: boolean;
  questionId: string;
  onClose: () => void;
  onQuestionDelete: () => void;
}

export const DeleteQuestionTemplateDialog = (props: Props) => {
  const actionButtons = (
    <Button
      color={"error"}
      endIcon={<DeleteIcon />}
      onClick={() => {
        props.onQuestionDelete();
        props.onClose();
      }}
    >
      Smazat
    </Button>
  );

  return (
    <AbstractDialog open={props.open} onClose={props.onClose} title={"Smazat otázku"} buttons={actionButtons}>
      Opravdu si přejete otázku smazat?
    </AbstractDialog>
  );
};
