import { WardRoundOccurrence } from "../../model/ward-round/WardRoundOccurrence";
import { api } from "../api";
import { parseWardRoundOccurrence } from "../../parsers/occurrence/parseWardRoundOccurrence";

export const getCareWardRoundOccurrences = async (
  patientId: string,
  careId: string
): Promise<WardRoundOccurrence[]> => {
  const response = await api.services.patientCare.getWardRoundOccurrences(patientId, careId);
  return response.data.map(parseWardRoundOccurrence);
};
