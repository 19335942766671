import { ComponentsOverrides, ComponentsProps, Theme } from "@mui/material";

export const MuiButtonGroupOverrides: {
  defaultProps?: ComponentsProps["MuiButtonGroup"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiButtonGroup"];
} = {
  defaultProps: {
    variant: "contained",
  },
  styleOverrides: {
    root: {
      boxShadow: "none",
    },
    contained: {
      ".MuiButtonGroup-grouped:not(:last-of-type)": {
        "&, &.Mui-disabled": {
          borderRight: "none",
        },
      },
    },
  },
};
