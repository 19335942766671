import { BarChartData } from "../statistics/BarChart";

export interface BloodPressureValue {
  systolic: number;
  diastolic: number;
}

export type BloodPressureBarChartData = { systolic: BarChartData[]; diastolic: BarChartData[] };

export const isBloodPressureValue = (data: unknown): data is BloodPressureValue => {
  return data !== null && typeof data === "object" && "systolic" in data && "diastolic" in data;
};
