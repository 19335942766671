import { useAlerts } from "../../../../hooks/useAlerts";
import { useAsyncCall } from "react-hook-async-call";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { AbstractDialog } from "../../../ui/dialogs/AbstractDialog";
import { DialogProps } from "../../../ui/dialogs/props/DialogProps";
import { Tag } from "../../../../model/tag/Tag";
import { deleteTag } from "../../../../services/tags/deleteTag";

type Props = DialogProps & {
  tag: Tag;
  onSuccess: () => void;
};

export const TagDeleteDialog = (props: Props) => {
  const alerts = useAlerts();

  const [deleteTagCall] = useAsyncCall(
    deleteTag,
    undefined,
    () => {
      alerts.success("Štítek byl smazán");
      props.onSuccess();
      props.onClose();
    },
    () => {
      alerts.error("Při mazání štítku došlo k chybě");
    }
  );

  const handleDelete = async () => {
    await deleteTagCall(props.tag.id);
  };

  const actionButtons = (
    <Button color={"error"} endIcon={<DeleteIcon />} onClick={handleDelete}>
      Smazat
    </Button>
  );

  return (
    <AbstractDialog open={props.open} onClose={props.onClose} title={"Smazat štítek"} buttons={actionButtons}>
      Opravdu si přejete smazat štítek {props.tag.value}?
    </AbstractDialog>
  );
};
