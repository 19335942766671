import { QuestionnaireTemplateDtoIn, QuestionnaireTemplateDtoOut } from "../../generated";
import { QuestionnaireTemplate } from "../../model/templates/QuestionnaireTemplate";
import { parseQuestionTemplate } from "./parseQuestionTemplate";

export const parseQuestionnaireTemplate = (data: QuestionnaireTemplateDtoOut): QuestionnaireTemplate => ({
  id: data.id,
  title: data.title,
  questions: data.questions.map(parseQuestionTemplate),
});

export const fromQuestionnaireTemplateToGenerated = (data: QuestionnaireTemplate): QuestionnaireTemplateDtoIn => ({
  title: data.title,
  questions: data.questions.map((question) => question.id),
});
