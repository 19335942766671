import { ComponentsOverrides, ComponentsProps, Theme } from "@mui/material";

export const MuiFormHelperText: {
  defaultProps?: ComponentsProps["MuiFormHelperText"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiFormHelperText"];
} = {
  styleOverrides: {
    root: {
      fontSize: 12,
      backgroundColor: "none",
      marginLeft: 0,
    },
  },
};
