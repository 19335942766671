import { api } from "../api";
import { parseCareTemplate } from "../../parsers/template/parseCareTemplate";
import { CareTemplate } from "../../model/templates/CareTemplate";

// TODO: https://github.com/mild-blue/health-warden/issues/645
export const getCareTemplate = async (careName: string): Promise<CareTemplate | undefined> => {
  const result = await api.services.careTemplates.getAllCareTemplates();
  const allTemplates = result.data.map(parseCareTemplate);
  return allTemplates.find((template) => template.title === careName);
};
