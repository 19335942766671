import { TagDtoOut } from "../../generated";
import { Tag } from "../../model/tag/Tag";
import { parseTagCategory } from "./parseTagCategory";

export const parseTag = (data: TagDtoOut): Tag => {
  return {
    id: data.id,
    value: data.value,
    category: parseTagCategory(data.category),
  };
};
