import { api } from "../api";
import {
  fromWardRoundTemplateToGenerated,
  parseWardRoundTemplate,
} from "../../parsers/template/parseWardRoundTemplate";
import { WardRoundTemplate } from "../../model/templates/WardRoundTemplate";

export const postWardRoundTemplate = async (wardRoundTemplate: WardRoundTemplate) => {
  const result = await api.services.wardRoundTemplates.createWardRoundTemplate(
    fromWardRoundTemplateToGenerated(wardRoundTemplate)
  );

  return parseWardRoundTemplate(result.data);
};
