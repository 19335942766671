import { api } from "../api";
import { OccurrenceDetail } from "../../model/occurrence/OccurrenceDetail";
import { parseOccurrenceDetail } from "../../parsers/occurrence/parseOccurrenceDetail";

export const getOccurrenceDetail = async (
  patientId: string,
  wardRoundId: string,
  occurrenceId: string
): Promise<OccurrenceDetail> => {
  const response = await api.services.patientWardRoundOccurrence.getWardRoundOccurrence(
    patientId,
    wardRoundId,
    occurrenceId
  );
  return parseOccurrenceDetail(response.data);
};
