import { ComponentsOverrides, ComponentsProps, Theme } from "@mui/material";

export const MuiAlertOverrides: {
  defaultProps?: ComponentsProps["MuiAlert"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiAlert"];
} = {
  styleOverrides: {
    root: {
      display: "flex",
      ".MuiAlert-action": { padding: "2px 0 0 16px", marginRight: 0 },
    },
    icon: {
      alignItems: "center",
    },
    message: {
      flex: 1,
    },
  },
};
