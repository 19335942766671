import { Button, Stack, SxProps } from "@mui/material";
import { dataGridHideHeader, dataGridStyles, outerBoxStyles } from "../../../theme/overrides/MuiDatagridOverrides";
import {
  DataGridPremium,
  GridColDef,
  GridRowId,
  GridRowIdGetter,
  GridRowsProp,
  GridToolbarContainer,
  GridValidRowModel,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { PaginationProps } from "../../../model/PaginationProps";
import { useEffect, useState } from "react";
import { fromDateToString } from "../../../parsers/parseDate";
import { DownloadOutlined } from "@mui/icons-material";

interface Props<R extends GridValidRowModel> extends Partial<PaginationProps> {
  rows?: GridRowsProp<R>;
  columns: GridColDef<R>[];
  selectedIds?: GridRowId[];
  onSelectedIdsChange?: (ids: string[]) => void;
  hideHeader?: boolean;
  getRowId?: GridRowIdGetter<R>;
  noRowsMessage?: string;
  showCheckbox?: boolean;
  disableColumnSorting?: boolean;
  disableColumnMenu?: boolean;
  enableExport?: boolean;
}

export const AbstractDataGrid = <R,>({
  rows,
  columns,
  hideHeader = false,
  showCheckbox = false,
  disableColumnSorting = false,
  disableColumnMenu = false,
  enableExport = false,
  ...props
}: Props<R>) => {
  const apiRef = useGridApiRef();
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowId[]>([]);

  useEffect(() => {
    setSelectedRowIds(props.selectedIds ?? []);
  }, [props.selectedIds]);

  rows = rows ?? [];
  let gridStyle = dataGridStyles;

  if (hideHeader) {
    gridStyle = { ...gridStyle, ...dataGridHideHeader } as SxProps;
  }

  if (!rows.length) {
    return (
      <Stack justifyContent={"center"} alignItems={"center"} height={"50px"} bgcolor={"white"} borderRadius={1}>
        {props.noRowsMessage ?? "Žádná data"}
      </Stack>
    );
  }

  const handleExportClick = () => {
    apiRef.current.exportDataAsExcel({
      fileName: `health-warden-export-${fromDateToString(new Date())}`,
    });
  };

  const getInclinedSelectedCount = () => {
    if (selectedRowIds.length === 1) {
      return `${selectedRowIds.length} záznam`;
    } else if (selectedRowIds.length < 5) {
      return `${selectedRowIds.length} záznamy`;
    } else {
      return `${selectedRowIds.length} záznamů`;
    }
  };

  return (
    <Stack sx={outerBoxStyles}>
      <DataGridPremium
        apiRef={apiRef}
        rows={rows}
        columns={columns}
        sx={gridStyle}
        getRowSpacing={() => ({
          top: 5,
          bottom: 5,
        })}
        rowSelectionModel={selectedRowIds}
        onRowSelectionModelChange={(rowIds) => {
          const parsedRowIds = rowIds.map((id) => String(id));
          setSelectedRowIds(parsedRowIds);
          props.onSelectedIdsChange?.(parsedRowIds);
        }}
        getRowId={props.getRowId}
        checkboxSelection={showCheckbox}
        disableColumnMenu={disableColumnMenu}
        disableColumnSorting={disableColumnSorting}
        disableRowSelectionOnClick
        hideFooter={props.page === undefined}
        columnHeaderHeight={40}
        slots={{
          toolbar:
            selectedRowIds.length > 0 && enableExport
              ? () => (
                  <GridToolbarContainer sx={{ mb: 2 }}>
                    <Button onClick={handleExportClick} endIcon={<DownloadOutlined />}>
                      Stáhnout {getInclinedSelectedCount()} jako Excel
                    </Button>
                  </GridToolbarContainer>
                )
              : undefined,
        }}
        {...(props.page !== undefined
          ? {
              pagination: true,
              paginationMode: "server",
              rowCount: props.totalElements,
              paginationModel: { page: props.page, pageSize: props.pageSize ?? 10 },
              onPaginationModelChange: props.onPaginationChange,
              pageSizeOptions: [10, 20, 50, 100],
            }
          : {})}
      />
    </Stack>
  );
};
