import { Tooltip, tooltipClasses } from "@mui/material";
import { TooltipProps } from "@mui/material/Tooltip/Tooltip";
import { styled } from "@mui/material/styles";
import { FontWeight } from "../../theme/utils";
import { Colors } from "../../theme/colors";
import React, { ReactNode } from "react";

type Props = Omit<TooltipProps, "arrow" | "title">;

export const Tip = styled(
  ({ className, title, childSpan = true, ...props }: Props & { title?: ReactNode; childSpan?: boolean }) => {
    // if no title is given and onMouseOver event is not set, we don't want to render the tooltip
    // this helps performance
    if (!title && !props.onMouseOver) {
      return props.children;
    }

    return (
      <Tooltip
        {...props}
        title={title && <span style={{ whiteSpace: "pre-line" }}>{title}</span>}
        arrow
        classes={{ popper: className }}
      >
        {childSpan ? <span style={{ lineHeight: 1 }}>{props.children}</span> : props.children}
      </Tooltip>
    );
  }
)(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: Colors.darkerAlpha,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: Colors.darkerAlpha,
    fontWeight: FontWeight.SemiBold,
    fontSize: 12,
  },
}));
