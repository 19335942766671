import { IconButton, Stack } from "@mui/material";
import { TextInput } from "../../../ui/form/inputs/TextInput";
import { SeverityIcon } from "../icons/SeverityIcon";
import CloseIcon from "@mui/icons-material/Close";
import { AnswerSeverityDialog } from "../dialogs/AnswerSeverityDialog";
import { useState } from "react";
import { UseFieldArrayReturn, UseFormReturn } from "react-hook-form";
import { QuestionTemplate } from "../../../../model/templates/QuestionTemplate";
import { SeverityStatus } from "../../../../model/enum/SeverityStatus";
import { AlertNoteDialog } from "../dialogs/AlertNoteDialog";
import { IconColors } from "../../../../theme/utils";
import { AlertNoteIcon } from "../icons/AlertNoteIcon";

interface Props {
  form: UseFormReturn<QuestionTemplate>;
  fieldArray: UseFieldArrayReturn<QuestionTemplate, "answers">;
  index: number;
}

export const AnswerTemplate = ({ form, index, fieldArray }: Props) => {
  const [severityDialogOpen, setSeverityDialogOpen] = useState<boolean>(false);
  const [alertNoteDialogOpen, setAlertNoteDialogOpen] = useState<boolean>(false);

  const handleSeveritySubmit = (severity: SeverityStatus) => {
    form.setValue(`answers.${index}.severity`, severity, { shouldDirty: true });
    setSeverityDialogOpen(false);
  };

  let textError;
  if (form.formState.errors.answers) {
    textError = form.formState.errors.answers[index]?.text;
  }

  return (
    <>
      <Stack direction={"row"} gap={0.5} width={"100%"}>
        <Stack width={"100%"}>
          <TextInput
            title={"odpověď"}
            hideTitle
            required
            name={`answers.${index}.text`}
            form={form}
            fieldError={textError}
          />
        </Stack>
        <Stack alignItems={"center"} direction={"row"}>
          <IconButton onClick={() => setSeverityDialogOpen(true)}>
            <SeverityIcon severity={form.watch(`answers.${index}.severity`)} />
          </IconButton>
          <IconButton onClick={() => setAlertNoteDialogOpen(true)}>
            <AlertNoteIcon isWarning={!!form.watch(`answers.${index}.alertNote`)} />
          </IconButton>
          <IconButton onClick={() => fieldArray.remove(index)} sx={IconColors}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </Stack>
      <AnswerSeverityDialog
        open={severityDialogOpen}
        onClose={() => setSeverityDialogOpen(false)}
        onSubmit={(severity) => handleSeveritySubmit(severity)}
        currentSeverity={form.watch(`answers.${index}.severity`)}
      />
      <AlertNoteDialog
        onClose={() => setAlertNoteDialogOpen(false)}
        open={alertNoteDialogOpen}
        index={index}
        form={form}
      />
    </>
  );
};
