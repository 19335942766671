import { api } from "../api";
import { PatientCreate } from "../../model/patient/PatientCreate";
import { fromPatientCreateToGenerated } from "../../parsers/patient/to-generated/fromPatientCreateToGenerated";
import { Patient } from "../../model/patient/Patient";
import { parsePatient } from "../../parsers/patient/parsePatient";

export const putPatient = async (id: string, data: PatientCreate): Promise<Patient> => {
  const response = await api.services.patient.updatePatient(id, fromPatientCreateToGenerated(data));
  return parsePatient(response.data);
};
