import { Stack } from "@mui/material";
import { Border } from "../../../../../theme/utils";
import { Colors } from "../../../../../theme/colors";
import React from "react";

interface Props {
  days: number;
}

export const NumberOfOccurrences = (props: Props) => {
  const lineStyle = { backgroundColor: Colors.grey200, width: 4, height: 80, borderRadius: 2 };

  return (
    <Stack alignItems={"center"} direction={"row"} justifyContent={"center"} gap={0.5}>
      {[...Array(3)].map((_, index) => (
        <Stack key={index} sx={lineStyle} />
      ))}

      <Stack
        alignItems={"center"}
        justifyContent={"center"}
        sx={{
          position: "absolute",
          backgroundColor: "white",
          height: 35,
          width: 35,
          border: Border.Grey.Thick,
          borderRadius: 15,
        }}
      >
        <strong>x{props.days}</strong>
      </Stack>
    </Stack>
  );
};
