import { api } from "../api";
import { StatisticsPeriod } from "../../model/enum/StatisticsPeriod";
import { fromPeriodToSinceUntil } from "../../helpers/fromPeriodToSinceUntil";
import { BloodPressureBarChartData } from "../../model/challenge/BloodPressureValue";

import { parseBloodPressureStatistics } from "../../parsers/statistics/parseStatisticsForBarChart";

export const getBloodPressureStatistics = async (
  patientId: string,
  period: StatisticsPeriod
): Promise<BloodPressureBarChartData> => {
  const { since, until } = fromPeriodToSinceUntil(period);
  const response = await api.services.patientChallenge.getPatientChallengesProgress(patientId, since, until);
  return parseBloodPressureStatistics(response.data.bloodPressure, since, until);
};
