import { formatDateTime } from "../../helpers/formatDateTime";
import moment from "moment/moment";
import { Tip } from "./Tip";
import React from "react";
import { Box } from "@mui/material";

type Props = {
  date: Date;
};

export const DateTimeWithTip = ({ date }: Props) => {
  return (
    <Tip title={formatDateTime(date)}>
      <Box
        sx={{
          display: "inline",
          textDecoration: "underline dotted",
          cursor: "pointer",
        }}
      >
        {moment(date).fromNow()}
      </Box>
    </Tip>
  );
};
