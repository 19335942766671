import { Card } from "../../../ui/cards/Card";
import { Colors } from "../../../../theme/colors";
import { Box, IconButton, Stack } from "@mui/material";
import { QuestionIcon } from "../icons/QuestionIcon";
import { Close } from "@mui/icons-material";
import { QuestionTemplate } from "../../../../model/templates/QuestionTemplate";
import { IconColors } from "../../../../theme/utils";
import { QuestionTemplateDetailDialog } from "../dialogs/QuestionTemplateDetailDialog";
import { useState } from "react";
import { QuestionnaireTemplate } from "../../../../model/templates/QuestionnaireTemplate";

interface Props {
  question: QuestionTemplate;
  questionnaire: QuestionnaireTemplate;
  onDeleteClick: () => void;
  onQuestionSubmit: (questionnaireTemplate: QuestionnaireTemplate) => void;
}

export const QuestionRow = (props: Props) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  return (
    <>
      <Stack width={"100%"} direction={"row"} justifyContent={"center"} alignItems={"center"} gap={1}>
        <Card
          noPadding
          sx={{
            width: "100%",
            "&:hover": { color: Colors.primary, ".MuiSvgIcon-root": { color: Colors.primary } },
          }}
          onClick={() => setDialogOpen(true)}
        >
          <Stack direction={"row"}>
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              sx={{ padding: "11px 15px", color: Colors.grey500, backgroundColor: Colors.grey50 }}
            >
              <QuestionIcon questionType={props.question.type} />
            </Stack>
            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} p={1} gap={1} flex={1}>
              <Box
                maxWidth={"700px"}
                sx={{
                  whiteSpace: "noWrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {props.question.text.replace(/<\/?[^>]+(>|$)/g, "")}
              </Box>
              {!props.question.required && <Box sx={{ color: Colors.grey500 }}>Nepovinná</Box>}
            </Stack>
          </Stack>
        </Card>
        <IconButton onClick={props.onDeleteClick} sx={IconColors}>
          <Close />
        </IconButton>
      </Stack>
      {dialogOpen && (
        <QuestionTemplateDetailDialog
          questionnaire={props.questionnaire}
          questionType={props.question.type}
          onSubmit={props.onQuestionSubmit}
          onClose={() => setDialogOpen(false)}
          dialogOpen={dialogOpen}
          data={props.question}
        />
      )}
    </>
  );
};
