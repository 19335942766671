import { Card, Stack, TextField, Typography } from "@mui/material";
import { FormEvent, useState } from "react";
import { AnimatedButton } from "../ui/buttons/AnimatedButton";
import { ArrowForward } from "@mui/icons-material";
import { Colors } from "../../theme/colors";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "../../model/constants/NavigationRoutes";
import { useUser } from "../../hooks/useUser";
import { useAsyncCall } from "react-hook-async-call";
import { useAlerts } from "../../hooks/useAlerts";

export const LoginPage = () => {
  const alert = useAlerts();
  const user = useUser();
  const [logInCall, , loading] = useAsyncCall(
    () => user.logIn(username, password),
    undefined,
    () => {
      alert.success("Úspěšne přihlášen");
      navigate(NavigationRoutes.patients.getLink());
    },
    () => alert.error("Chybně zadané heslo nebo uživatelské jméno")
  );
  const navigate = useNavigate();

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const handleLogIn = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!username.length || !password.length) {
      return;
    }

    await logInCall();
  };

  const areInputsFilled = username !== "" && password !== "";

  return (
    <Stack flexGrow={1} justifyContent={"center"} alignItems={"center"}>
      <Typography variant={"h1"} sx={{ color: Colors.primary }}>
        HealthWarden
      </Typography>
      <Card variant="outlined" sx={{ p: 3, width: 500, maxWidth: "100%" }}>
        <form onSubmit={(e) => handleLogIn(e)}>
          <Typography variant={"h3"}>Přihláste se</Typography>
          <Stack spacing={3}>
            <Stack spacing={1}>
              <TextField
                value={username}
                autoComplete={"email"}
                placeholder={"Uživatelské jméno"}
                onChange={(e) => setUsername(e.currentTarget.value)}
              />
              <TextField
                value={password}
                autoComplete={"current-password"}
                placeholder={"Heslo"}
                type={"password"}
                onChange={(e) => setPassword(e.currentTarget.value)}
              />
            </Stack>
            <AnimatedButton
              type={"submit"}
              disabled={loading || !areInputsFilled}
              endIcon={<ArrowForward />}
              animation={"slide-right"}
            >
              Přihlásit se
            </AnimatedButton>
          </Stack>
        </form>
      </Card>
    </Stack>
  );
};
