import moment from "moment/moment";

type ObjectWithDate = {
  date: Date;
};

export const sortByDate = <K extends ObjectWithDate>(items: K[], sortBy: "asc" | "desc" = "asc"): K[] => {
  return items.sort((a, b) => {
    const aMoment = moment(a.date);
    const bMoment = moment(b.date);
    if (aMoment.isBefore(bMoment)) return sortBy === "asc" ? -1 : 1;
    if (aMoment.isAfter(bMoment)) return sortBy === "asc" ? 1 : -1;
    return 0;
  });
};
