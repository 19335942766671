export const NavigationRoutes = {
  login: {
    getLink: () => `/login`,
  },
  patients: {
    getLink: () => `/patients`,
  },
  patientDetail: {
    getLink: (patientId: string) => `/patients/${patientId}/`,
  },
  patientStatistics: {
    getLink: (patientId: string) => `/patients/${patientId}/statistics`,
  },
  questionnaireTemplates: {
    getLink: () => `/questionnaire-templates`,
  },
  wardRoundTemplates: {
    getLink: () => `/ward-round-templates`,
  },
  wardRoundTemplateDetail: {
    getLink: (wardRoundTemplateId: string | undefined) => `/ward-round-templates/${wardRoundTemplateId}`,
  },
  careTemplates: {
    getLink: () => `/care-templates`,
  },
};
