import { AbstractDialog } from "../../../ui/dialogs/AbstractDialog";
import { useAlerts } from "../../../../hooks/useAlerts";
import { useAsyncCall } from "react-hook-async-call";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button } from "@mui/material";
import { deleteQuestionnaireTemplate } from "../../../../services/questionnaire-template/deleteQuestionnaireTemplate";

interface Props {
  open: boolean;
  questionnaireTemplateId: string;
  onClose: () => void;
  onSuccess: (questionnaireTemplateId: string) => void;
}

export const DeleteQuestionnaireDialog = (props: Props) => {
  const alerts = useAlerts();

  const [deleteQuestionnaireCall] = useAsyncCall(
    deleteQuestionnaireTemplate,
    undefined,
    () => {
      alerts.success("Dotazník smazán");
      props.onSuccess(props.questionnaireTemplateId);
      props.onClose();
    },
    () => {
      alerts.error("Při mazání dotazníku došlo k chybě");
    }
  );

  const handleDelete = async () => {
    await deleteQuestionnaireCall(props.questionnaireTemplateId);
  };

  const actionButtons = (
    <Button color={"error"} endIcon={<DeleteIcon />} onClick={handleDelete}>
      Smazat
    </Button>
  );

  return (
    <AbstractDialog open={props.open} onClose={props.onClose} title={"Smazat dotazník"} buttons={actionButtons}>
      Opravdu si přejete dotazník smazat?
    </AbstractDialog>
  );
};
