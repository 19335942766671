import { AnimatedButton } from "../../ui/buttons/AnimatedButton";
import AddIcon from "@mui/icons-material/Add";
import { useAsyncCall } from "react-hook-async-call";
import { useOnMount } from "../../../hooks/useOnMount";
import { Loading } from "../../ui/loading/Loading";
import { QuestionnaireTemplateDetail } from "./QuestionnaireTemplateDetail";
import React, { useState } from "react";
import { QuestionnaireDetailDialog } from "./dialogs/QuestionnaireDetailDialog";
import { getQuestionnaireTemplates } from "../../../services/questionnaire-template/getQuestionnaireTemplates";
import { QuestionnaireTemplate } from "../../../model/templates/QuestionnaireTemplate";
import { DeleteQuestionnaireDialog } from "./dialogs/DeleteQuestionnaireDialog";
import { QuestionTemplate } from "../../../model/templates/QuestionTemplate";
import { DropResult } from "react-beautiful-dnd";
import { arrayMove } from "../../../helpers/arrayMove";
import { putQuestionnaireTemplate } from "../../../services/questionnaire-template/putQuestionnaireTemplate";
import { useAlerts } from "../../../hooks/useAlerts";
import { ItemList } from "../../layout/ItemList";

export const QuestionnaireTemplatesPage = () => {
  const alerts = useAlerts();

  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState<QuestionnaireTemplate>();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [questionnaireDialogOpened, setQuestionnaireDialogOpened] = useState<boolean>(false);
  const [deleteQuestionnaireDialogOpen, setDeleteQuestionnaireDialogOpen] = useState<boolean>(false);
  // use setAndSortQuestionnaires to set the questionnaires and sort them by title
  const [questionnaires, setQuestionnaires] = useState<QuestionnaireTemplate[]>([]);

  const [getQuestionnaireTemplatesCall, , loading] = useAsyncCall(getQuestionnaireTemplates, [], (result) => {
    if (!result.length) return;
    setAndSortQuestionnaires(result);
    if (!selectedQuestionnaire) return setSelectedQuestionnaire(result[0]);
    setSelectedQuestionnaire(result.find((questionnaire) => questionnaire.id === selectedQuestionnaire.id));
  });
  const [updateQuestionnaireCall] = useAsyncCall(putQuestionnaireTemplate, undefined);

  useOnMount(() => {
    getQuestionnaireTemplatesCall();
  });

  const handleQuestionDelete = async (questionTemplate: QuestionTemplate) => {
    const updatedQuestionsList = selectedQuestionnaire?.questions.filter((q) => q.id !== questionTemplate.id);
    if (!updatedQuestionsList?.length || !selectedQuestionnaire) return;
    const updatedQuestionnaire = { ...selectedQuestionnaire, questions: updatedQuestionsList };

    try {
      await updateQuestionnaireCall(updatedQuestionnaire.id, updatedQuestionnaire);
      alerts.success("Otázka smazána");
      setSelectedQuestionnaire(updatedQuestionnaire);
      setAndSortQuestionnaires(
        questionnaires.map((q) => (q.id === selectedQuestionnaire.id ? updatedQuestionnaire : q))
      );
    } catch (e) {
      alerts.error("Při mazání otázky nastala chyba");
    }
  };

  const updateQuestionnaireTemplate = (questionnaireTemplate: QuestionnaireTemplate) => {
    setSelectedQuestionnaire(questionnaireTemplate);
    const filteredQuestionnaires = questionnaires.filter((q) => q.id !== questionnaireTemplate.id);
    setAndSortQuestionnaires([...filteredQuestionnaires, questionnaireTemplate]);
  };

  const handleChangeOrder = async (result: DropResult) => {
    if (!selectedQuestionnaire || !result.destination) return;
    const sortedQuestions = arrayMove(selectedQuestionnaire.questions, result.source.index, result.destination?.index);
    const updatedQuestionnaire = { ...selectedQuestionnaire, questions: sortedQuestions };
    updateQuestionnaireTemplate(updatedQuestionnaire);
    try {
      await updateQuestionnaireCall(updatedQuestionnaire.id, updatedQuestionnaire);
      alerts.success("Nové pořadí uloženo");
    } catch (e) {
      alerts.error("Pořadí se nepodařilo uložit");
    }
  };

  const setAndSortQuestionnaires = (questionnaireTemplate: QuestionnaireTemplate[]) => {
    setQuestionnaires(questionnaireTemplate.sort((a, b) => a.title.localeCompare(b.title)));
  };

  const newQuestionnaireButton = (
    <AnimatedButton
      onClick={() => setQuestionnaireDialogOpened(true)}
      animation={"scale"}
      endIcon={<AddIcon />}
      variant={"contained"}
    >
      Nový dotazník
    </AnimatedButton>
  );

  const itemDetail = selectedQuestionnaire ? (
    <QuestionnaireTemplateDetail
      questionnaire={selectedQuestionnaire}
      onQuestionnaireEdit={() => {
        setQuestionnaireDialogOpened(true);
        setEditMode(true);
      }}
      onQuestionnaireDelete={() => {
        setDeleteQuestionnaireDialogOpen(true);
      }}
      onQuestionDelete={handleQuestionDelete}
      onQuestionSubmit={updateQuestionnaireTemplate}
      onDragEnd={handleChangeOrder}
    />
  ) : null;

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <ItemList
        title={"Dotazníky"}
        items={questionnaires}
        selectedItem={selectedQuestionnaire}
        headerButtons={newQuestionnaireButton}
        itemDetail={itemDetail}
        onItemClick={setSelectedQuestionnaire}
      />
      <QuestionnaireDetailDialog
        open={questionnaireDialogOpened}
        onClose={() => {
          setQuestionnaireDialogOpened(false);
          setEditMode(false);
        }}
        onSubmit={(questionnaireTemplate) => {
          updateQuestionnaireTemplate(questionnaireTemplate);
          setEditMode(false);
        }}
        data={editMode ? selectedQuestionnaire : undefined}
      />
      {selectedQuestionnaire && (
        <DeleteQuestionnaireDialog
          open={deleteQuestionnaireDialogOpen}
          onClose={() => {
            setDeleteQuestionnaireDialogOpen(false);
          }}
          onSuccess={(id) => {
            const filteredQuestionnaires = questionnaires.filter((questionnaire) => questionnaire.id !== id);
            setQuestionnaires(filteredQuestionnaires);
            setSelectedQuestionnaire(filteredQuestionnaires.length ? filteredQuestionnaires[0] : undefined);
          }}
          questionnaireTemplateId={selectedQuestionnaire.id}
        />
      )}
    </>
  );
};
