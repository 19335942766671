import { WardRoundOccurrenceDetailDtoOut } from "../../generated";
import { OccurrenceDetail } from "../../model/occurrence/OccurrenceDetail";
import { parseAnsweredQuestionnaire } from "../question/parseAnsweredQuestionnaire";
import { parseDate } from "../parseDate";
import { parseChallengeProgress } from "../challenges/parseChallengeProgress";
import { parseActionResponse } from "../actions/parseActionResponse";

export const parseOccurrenceDetail = (data: WardRoundOccurrenceDetailDtoOut): OccurrenceDetail => ({
  id: data.id,
  occurredAt: parseDate(data.occurredAt),
  isCompleted: data.isCompleted,
  questionnaires: data.answeredQuestionnaires.map(parseAnsweredQuestionnaire),
  challenges: data.challenges.filter((challenge) => challenge.enabled).map(parseChallengeProgress),
  actions: data.actions.filter((challenge) => challenge.enabled).map(parseActionResponse),
});
