import { Stack, SxProps, Typography } from "@mui/material";
import { ReactNode } from "react";

interface Props {
  children: ReactNode;
  title?: string;
  required: boolean;
  buttons?: ReactNode;
  sx?: SxProps;
}

export const InputWrapper = (props: Props) => {
  return (
    <Stack flex={1} sx={props.sx}>
      {props.title && (
        <Stack height={38} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
          <Stack direction={"row"} gap={0.5}>
            <Typography variant={"h4"} mb={0}>
              {props.title}
            </Typography>
            {props.required && <Typography color={"error.main"}>*</Typography>}
          </Stack>
          <Stack>{props.buttons}</Stack>
        </Stack>
      )}
      <Stack gap={1}>{props.children}</Stack>
    </Stack>
  );
};
