import { Stack, Typography } from "@mui/material";
import { Border } from "../../theme/utils";
import { ReactNode } from "react";

interface Props {
  newItemButton: ReactNode;
}

export const NoData = (props: Props) => (
  <Stack
    bgcolor={"white"}
    sx={{ width: "100%", height: "100%", p: 3, borderTop: Border.Grey.Thin }}
    justifyContent={"flex-start"}
    alignItems={"center"}
    gap={1}
  >
    <Typography variant={"h3"}>Pokračujte přidáním nové položky</Typography>
    {props.newItemButton}
  </Stack>
);
