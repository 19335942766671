import { Editor as TinyMCEEditorReact } from "opentiny-react";
import { Editor as TinyMCEEditor } from "opentiny";
import { useEffect, useRef } from "react";
import { Stack, Typography } from "@mui/material";

type Props = {
  value: string;
  onValueChange: (value: string) => void;
  note?: string;
};

const plugins =
  "preview importcss searchreplace fullscreen image link charmap nonbreaking insertdatetime advlist lists wordcount charmap code";
const toolbar = `undo redo | blocks fontsize | bold italic underline strikethrough subscript superscript | image link charmap | numlist bullist align | forecolor backcolor removeformat | fullscreen | code`;

export const Editor = (props: Props) => {
  const editorRef = useRef<TinyMCEEditor | null>(null);

  useEffect(() => {
    if (editorRef.current?.getContent() !== props.value) {
      editorRef.current?.setContent(props.value);
    }
  }, [props.value]);

  const handleChange = (value: string) => {
    props.onValueChange(value);
  };

  return (
    <Stack>
      {props.note && <Typography variant={"caption"}>{props.note}</Typography>}
      <TinyMCEEditorReact
        tinymceScriptSrc={window.origin + "/opentiny/tinymce.min.js"}
        onInit={(_: unknown, editor: TinyMCEEditor) => {
          editorRef.current = editor;
          setTimeout(() => {
            editorRef.current?.setContent(props.value);
            editorRef.current?.focus();
          });
        }}
        onEditorChange={handleChange}
        init={{
          menubar: false,
          plugins,
          toolbar,
          convert_urls: false, // when set to true tiny changes absolute URL to relative!
          language: "cs",
          language_url: "/tinylangs/cs.js",
          browser_spellcheck: true,
          quickbars_insert_toolbar: false,
          quickbars_selection_toolbar:
            "blocks fontsize | bold italic underline strikethrough | forecolor backcolor removeformat | link",
          height: "300px",
          font_size_formats: "8pt 9pt 10pt 11pt 12pt 14pt 18pt 20pt 22pt 24pt 26pt 28pt 36pt",
          resize: true,
          elementpath: false,
          branding: false,
          statusbar: true,
          editable_root: true,
          extended_valid_elements: "svg[*],span[*]",
          highlight_on_focus: false,
          block_formats: "Odstavec=p; Nadpis 1=h1; Nadpis 2=h2; Nadpis 3=h3; Nadpis 4=h4;",
          contextmenu: "table",
          link_target_list: false,
          inline_boundaries_selector: "a[href],code,.mce-annotation",
          entity_encoding: "raw", // fixes bug with encoding czech characters
          paste_webkit_styles: "all", // tiny otherwise removes all inline styles if the pasted content is not tiny content
          nonbreaking_force_tab: 8,
          link_assume_external_targets: "https",
          images_upload_handler: (blobInfo) => {
            const base64str = "data:" + blobInfo.blob().type + ";base64," + blobInfo.base64();
            return Promise.resolve(base64str);
          },
        }}
      />
    </Stack>
  );
};
