import moment from "moment";
import { StatisticsPeriod } from "../model/enum/StatisticsPeriod";

export const fromPeriodToSinceUntil = (
  statisticsPeriod: StatisticsPeriod = StatisticsPeriod.Week
): { since: string; until: string } => {
  const now = moment();
  const since = now.clone();
  since.subtract(statisticsPeriod, "days");
  return { since: since.toISOString(), until: now.toISOString() };
};
