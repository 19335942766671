import { IconColors } from "../../../theme/utils";
import { Stack } from "@mui/material";
import { QuestionnaireTemplate } from "../../../model/templates/QuestionnaireTemplate";
import { NoQuestions } from "./parts/NoQuestions";
import { DeleteQuestionTemplateDialog } from "./dialogs/DeleteQuestionTemplateDialog";
import { useState } from "react";
import { QuestionTemplate } from "../../../model/templates/QuestionTemplate";
import { ItemDetailHeader } from "../../layout/ItemDetailHeader";
import { Item } from "../../ui/menu/Item";
import { IconMenu } from "../../ui/menu/IconMenu";
import { ButtonMenu } from "../../ui/menu/ButtonMenu";
import { QuestionType } from "../../../model/enum/QuestionType";
import AddIcon from "@mui/icons-material/Add";
import { NewQuestionItem } from "./parts/NewQuestionItem";
import { QuestionRow } from "./question/QuestionRow";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import DragHandleIcon from "@mui/icons-material/DragHandle";

interface Props {
  questionnaire: QuestionnaireTemplate;
  onQuestionnaireEdit: () => void;
  onQuestionnaireDelete: () => void;
  onQuestionDelete: (questionTemplate: QuestionTemplate) => void;
  onQuestionSubmit: (questionnaireTemplate: QuestionnaireTemplate) => void;
  onDragEnd: (result: DropResult) => void;
}

export const QuestionnaireTemplateDetail = ({ questionnaire, ...props }: Props) => {
  const [deleteQuestionnaireDialogOpen, setDeleteQuestionDialogOpen] = useState<boolean>(false);
  const [selectedQuestion, setSelectedQuestion] = useState<QuestionTemplate>();

  return (
    <>
      <ItemDetailHeader title={questionnaire.title}>
        <ButtonMenu text={"Přidat otázku"} color={"success"} endIcon={<AddIcon />}>
          {Object.values(QuestionType).map((questionType) => (
            <NewQuestionItem
              key={questionType}
              questionType={questionType}
              questionnaire={questionnaire}
              onSubmit={props.onQuestionSubmit}
            />
          ))}
        </ButtonMenu>
        <IconMenu>
          <Item title={"Editovat"} onClick={props.onQuestionnaireEdit} />
          <Item title={"Smazat dotazník"} onClick={props.onQuestionnaireDelete} />
        </IconMenu>
      </ItemDetailHeader>
      <Stack width={"100%"}>
        {!questionnaire.questions.length && (
          <NoQuestions questionnaire={questionnaire} onSubmit={props.onQuestionSubmit} />
        )}
        <DragDropContext onDragEnd={props.onDragEnd}>
          <Droppable droppableId={"questions"}>
            {(provided) => (
              <Stack {...provided.droppableProps} ref={provided.innerRef} gap={1}>
                {questionnaire.questions.map((question, index) => (
                  <Draggable key={question.id} draggableId={question.id} index={index}>
                    {(provided) => (
                      <Stack
                        alignItems={"center"}
                        direction={"row"}
                        gap={2}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        <Stack {...provided.dragHandleProps} sx={IconColors}>
                          <DragHandleIcon />
                        </Stack>
                        <QuestionRow
                          question={question}
                          questionnaire={questionnaire}
                          onQuestionSubmit={props.onQuestionSubmit}
                          onDeleteClick={() => {
                            setSelectedQuestion(question);
                            setDeleteQuestionDialogOpen(true);
                          }}
                        />
                      </Stack>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Stack>
            )}
          </Droppable>
        </DragDropContext>
      </Stack>
      {selectedQuestion && (
        <DeleteQuestionTemplateDialog
          open={deleteQuestionnaireDialogOpen}
          onClose={() => setDeleteQuestionDialogOpen(false)}
          questionId={selectedQuestion?.id}
          onQuestionDelete={() => props.onQuestionDelete(selectedQuestion)}
        />
      )}
    </>
  );
};
