import { QuestionType } from "../../../../model/enum/QuestionType";
import { Box, Chip, Divider, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material";
import { AnsweredQuestionnaire } from "../../../../model/questions/AnsweredQuestionnaire";
import { QuestionAnswer } from "@mui/icons-material";
import { AnsweredQuestion } from "../../../../model/questions/AnsweredQuestion";
import { formatBloodPressure } from "../../../../helpers/formatBloodPressure";
import { Colors } from "../../../../theme/colors";
import CheckIcon from "@mui/icons-material/Check";
import { Card } from "../../../ui/cards/Card";

export const WardRoundQuestionnaireAnswers = ({ data }: { data: AnsweredQuestionnaire }) => {
  const answeredQuestions = data.questions.filter((question) => question.userAnswers.length);

  if (answeredQuestions.length === 0 && data.questions.length !== 0) {
    return (
      <Stack alignItems={"center"} my={3}>
        <Typography fontWeight={"bold"}>Žádné otázky zatím nebyly zodpovězeny</Typography>
      </Stack>
    );
  }

  const userAnswers = (answeredQuestion: AnsweredQuestion) => {
    if (answeredQuestion.question.type === QuestionType.MultiChoiceAnswer) {
      return (
        <Stack>
          {answeredQuestion.userAnswers.map((answer, index) => (
            <Stack key={index} direction={"row"}>
              <Typography sx={{ fontWeight: "bold" }} whiteSpace={"pre"}>
                {index + 1}
                {". "}
              </Typography>
              {answer}
            </Stack>
          ))}
        </Stack>
      );
    }
    if (answeredQuestion.question.type === QuestionType.BloodPressure) {
      return formatBloodPressure(answeredQuestion.bloodPressure);
    }
    return <>{answeredQuestion.userAnswers}</>;
  };

  return (
    <List sx={{ p: 0 }}>
      {answeredQuestions.map((answeredQuestion, index) => (
        <Stack key={index}>
          <Divider />
          <ListItem alignItems={"flex-start"}>
            <ListItemIcon>
              <QuestionAnswer />
            </ListItemIcon>
            <ListItemText>
              <Stack direction={"row"} gap={2}>
                {(() => {
                  if (answeredQuestion.question.type === QuestionType.Information) {
                    return (
                      <Stack flex={1} gap={2}>
                        <Stack color={Colors.success} direction={"row"} alignItems={"center"} gap={0.5}>
                          <CheckIcon fontSize={"small"} />
                          Prohlednuto
                        </Stack>
                        <Card>
                          <Box dangerouslySetInnerHTML={{ __html: answeredQuestion.question.text }} />
                        </Card>
                      </Stack>
                    );
                  }

                  return (
                    <Stack flex={1} gap={1}>
                      <Typography sx={{ fontWeight: "bold" }}>{answeredQuestion.question.text}</Typography>
                      <Stack direction={"row"}>
                        <Typography sx={{ fontWeight: "bold" }} whiteSpace={"pre"}>
                          Odpověd:{" "}
                        </Typography>
                        {userAnswers(answeredQuestion)}
                      </Stack>
                      {answeredQuestion.userNote?.length && (
                        <Stack direction={"row"}>
                          <Typography sx={{ fontWeight: "bold" }} whiteSpace={"pre"}>
                            Komentář:{" "}
                          </Typography>
                          {answeredQuestion.userNote}
                        </Stack>
                      )}
                    </Stack>
                  );
                })()}
                <Stack direction={"row"} flex-wrap={"wrap"} gap={1}>
                  <Chip label={data.title} />
                  <Chip label={answeredQuestion.question.type.toLowerCase()} />
                </Stack>
              </Stack>
            </ListItemText>
          </ListItem>
        </Stack>
      ))}
    </List>
  );
};
