import {
  BloodPressureChallengeTemplateDtoOut,
  CareTemplateDtoInChallengesInner,
  CareTemplateDtoOutChallengesInner,
  ChallengeTemplateDtoOutBase,
  GoalType,
  NumericChallengeTemplateDtoOut,
} from "../../generated";
import {
  CareTemplateBloodPressureChallenge,
  CareTemplateChallenge,
  CareTemplateChallengeBase,
  CareTemplateNumericChallenge,
} from "../../model/templates/CareTemplateChallenge";
import { parseChallengeType } from "../challenges/parseChallengeType";
import { ChallengeType } from "../../model/enum/Challenge";
import { fromChallengeTypeToGenerated } from "../challenges/to-generated/fromChallengeTypeToGenerated";

export const parseCareChallenge = (data: CareTemplateDtoOutChallengesInner): CareTemplateChallenge => {
  switch (data.goalType) {
    case GoalType.Steps:
    case GoalType.Water:
      return parseCareTemplateWaterChallenge(data as NumericChallengeTemplateDtoOut);
    case GoalType.Sleep:
      return parseCareTemplateSleepChallenge(data as NumericChallengeTemplateDtoOut);
    case GoalType.HeartRate:
      return parseCareTemplateNumericChallenge(data as NumericChallengeTemplateDtoOut);
    case GoalType.Pressure:
      return parseCareTemplateBloodPressureChallenge(data as BloodPressureChallengeTemplateDtoOut);
  }
};

export const parseCareChallengeBase = (data: ChallengeTemplateDtoOutBase): CareTemplateChallengeBase => {
  return {
    id: data.id,
    name: data.name,
    description: data.description,
    discriminator: data.discriminator,
    type: parseChallengeType(data.goalType),
    enabled: data.enabled,
  };
};

export const parseCareTemplateNumericChallenge = (
  data: NumericChallengeTemplateDtoOut
): CareTemplateNumericChallenge => {
  return {
    ...parseCareChallengeBase(data),
    minGoal: data.minGoal,
    maxGoal: data.maxGoal,
  };
};

export const parseCareTemplateBloodPressureChallenge = (
  data: BloodPressureChallengeTemplateDtoOut
): CareTemplateBloodPressureChallenge => {
  return {
    ...parseCareChallengeBase(data),
    minGoal: data.minGoal,
    maxGoal: data.maxGoal,
  };
};

export const parseCareTemplateWaterChallenge = (data: NumericChallengeTemplateDtoOut): CareTemplateNumericChallenge => {
  // Converting liters to glasses when parsing data from backend
  return {
    ...parseCareChallengeBase(data),
    minGoal: data.minGoal * 4,
    maxGoal: data.maxGoal,
  };
};

export const parseCareTemplateSleepChallenge = (data: NumericChallengeTemplateDtoOut): CareTemplateNumericChallenge => {
  // Converting minutes to hours when parsing data from backend
  return {
    ...parseCareChallengeBase(data),
    minGoal: data.minGoal / 60,
    maxGoal: data.maxGoal,
  };
};

export const fromCareChallengeGenerated = (data: CareTemplateChallenge): CareTemplateDtoInChallengesInner => {
  switch (data.type) {
    case ChallengeType.Steps:
    case ChallengeType.Water:
      return fromCareTemplateWaterChallengeToGenerated(data as CareTemplateNumericChallenge);
    case ChallengeType.Sleep:
      return fromCareTemplateSleepChallengeToGenerated(data as CareTemplateNumericChallenge);
    case ChallengeType.HeartRate:
      return fromCareTemplateNumericChallengeToGenerated(data as CareTemplateNumericChallenge);
    case ChallengeType.Pressure:
      return fromCareTemplateBloodPressureChallengeToGenerated(data as CareTemplateBloodPressureChallenge);
  }
};

export const fromCareChallengeBaseToGenerated = (data: CareTemplateChallengeBase): ChallengeTemplateDtoOutBase => {
  return {
    id: data.id,
    name: data.name,
    description: data.description,
    discriminator: data.discriminator,
    goalType: fromChallengeTypeToGenerated(data.type),
    enabled: data.enabled,
  };
};

export const fromCareTemplateNumericChallengeToGenerated = (
  data: CareTemplateNumericChallenge
): NumericChallengeTemplateDtoOut => {
  return {
    ...fromCareChallengeBaseToGenerated(data),
    minGoal: data.minGoal,
    maxGoal: data.maxGoal,
  };
};

export const fromCareTemplateBloodPressureChallengeToGenerated = (
  data: CareTemplateBloodPressureChallenge
): BloodPressureChallengeTemplateDtoOut => {
  return {
    ...fromCareChallengeBaseToGenerated(data),
    minGoal: data.minGoal,
    maxGoal: data.maxGoal,
  };
};

export const fromCareTemplateWaterChallengeToGenerated = (
  data: CareTemplateNumericChallenge
): NumericChallengeTemplateDtoOut => {
  // Converting glasses to liters before sending data to backend
  return {
    ...fromCareChallengeBaseToGenerated(data),
    minGoal: data.minGoal / 4,
    maxGoal: data.maxGoal,
  };
};

export const fromCareTemplateSleepChallengeToGenerated = (
  data: CareTemplateNumericChallenge
): NumericChallengeTemplateDtoOut => {
  // Converting hours to minutes before sending data to backend
  return {
    ...fromCareChallengeBaseToGenerated(data),
    minGoal: data.minGoal * 60,
    maxGoal: data.maxGoal,
  };
};
