import { Grid, Stack, Typography } from "@mui/material";
import { useAsyncCall } from "react-hook-async-call";
import { useOnMount } from "../../../../../hooks/useOnMount";
import { Loading } from "../../../../ui/loading/Loading";
import { useParams } from "react-router-dom";
import React, { useState } from "react";
import { WardRoundOccurrenceCard } from "./WardRoundOccurrenceCard";
import { OccurrenceDetailDialog } from "../../dialogs/OccurrenceDetailDialog";
import { WardRoundOccurrence } from "../../../../../model/ward-round/WardRoundOccurrence";
import { useAlerts } from "../../../../../hooks/useAlerts";
import { getCareWardRoundOccurrences } from "../../../../../services/patient/getCareWardRoundOccurrences";

type Props = {
  careId: string;
};

export const PatientDetailWardRounds = ({ careId }: Props) => {
  const { patientId } = useParams() as { patientId: string };
  const alerts = useAlerts();
  const [getOccurrences, occurrences, loadingOccurrences] = useAsyncCall(
    () => getCareWardRoundOccurrences(patientId, careId),
    [],
    undefined,
    () => alerts.error("Proběhlé vizity nelze načíst")
  );
  const [occurrenceDetailDialogOpen, setOccurrenceDetailDialogOpen] = useState(false);
  const [selectedOccurrence, setSelectedOccurrence] = useState<WardRoundOccurrence>();

  useOnMount(() => {
    getOccurrences();
  });

  if (loadingOccurrences) {
    return <Loading />;
  }

  if (occurrences.length === 0) {
    return (
      <Stack alignItems={"center"}>
        <Typography fontWeight={"bold"}>Pacientovi zatím neproběhla žádná vizita</Typography>
      </Stack>
    );
  }

  return (
    <>
      <Grid container spacing={2}>
        {occurrences.map((occurrence, index) => (
          <WardRoundOccurrenceCard
            occurrence={occurrence}
            key={index}
            onClick={() => {
              setOccurrenceDetailDialogOpen(true);
              setSelectedOccurrence(occurrence);
            }}
          />
        ))}
      </Grid>
      {selectedOccurrence && (
        <OccurrenceDetailDialog
          open={occurrenceDetailDialogOpen}
          occurrence={selectedOccurrence}
          patientId={patientId}
          onClose={() => {
            setOccurrenceDetailDialogOpen(false);
            setSelectedOccurrence(undefined);
          }}
        />
      )}
    </>
  );
};
