import { QuestionTemplate } from "../../../model/templates/QuestionTemplate";
import { AnswerDtoIn, QuestionConditionTemplateDtoIn, QuestionTemplateDtoIn } from "../../../generated";
import { fromQuestionTypeToGenerated } from "../../question/to-generated/fromQuestionTypeToGenerated";
import { QuestionCondition } from "../../../model/templates/QuestionCondition";
import { QuestionType } from "../../../model/enum/QuestionType";
import { fromAnswerTemplateToGenerated } from "./fromAnswerTemplateToGenerated";

const questionTypesWithOptions = [
  QuestionType.SingleChoiceAnswer,
  QuestionType.MultiChoiceAnswer,
  QuestionType.WellBeing,
];

export const fromQuestionTemplateToGenerated = (data: QuestionTemplate): QuestionTemplateDtoIn => ({
  type: fromQuestionTypeToGenerated(data.type),
  text: data.text,
  answers: getQuestionAnswers(data),
  conditions: fromQuestionConditionsToGenerated(data.conditions),
  required: data.required,
});

const fromQuestionConditionsToGenerated = (data: QuestionCondition[]): QuestionConditionTemplateDtoIn[] => {
  const answerIds = [];
  for (const condition of data) {
    if (condition.answer) answerIds.push(condition.answer.id);
  }
  return answerIds.map((id) => ({ answerTemplateId: id }));
};

const getQuestionAnswers = (question: QuestionTemplate): AnswerDtoIn[] => {
  if (!questionTypesWithOptions.includes(question.type)) {
    return [];
  }
  return question.answers.map(fromAnswerTemplateToGenerated);
};
