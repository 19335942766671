import { Challenge, NumericChallenge } from "../../model/challenge/Challenge";
import { api } from "../api";
import { parsePatientChallenge } from "../../parsers/challenges/parsePatientChallenge";
import { ChallengeType } from "../../model/enum/Challenge";
import { litersInCup, minutesInHour } from "../../model/constants/Challenges";
import { isBloodPressureValue } from "../../model/challenge/BloodPressureValue";

export const getAllPatientChallenges = async (patientId: string): Promise<Challenge[]> => {
  const response = await api.services.patientChallenge.getPatientChallenges(patientId);
  return response.data.map(parsePatientChallenge);
};

export const getAllChallenges = async (patientId: string): Promise<Challenge[]> => {
  const patientData = await getAllPatientChallenges(patientId);
  return patientData.map(normalizeInputValues);
};

const normalizeInputValues = (data: Challenge): Challenge => {
  if ([data.minGoal, data.maxGoal].every(isBloodPressureValue)) {
    return data;
  }
  return normalizeNumericValues(data as NumericChallenge);
};

const normalizeNumericValues = (data: NumericChallenge): Challenge => {
  switch (data.type) {
    case ChallengeType.Sleep:
      return {
        ...data,
        minGoal: data.minGoal / minutesInHour,
        maxGoal: data.maxGoal / minutesInHour,
        value: data.value ? data.value / minutesInHour : undefined,
      };
    case ChallengeType.Water:
      return {
        ...data,
        minGoal: Math.floor(data.minGoal / litersInCup),
        maxGoal: Math.floor(data.maxGoal / litersInCup),
        value: data.value ? Math.floor(data.value / litersInCup) : undefined,
      };
  }

  return data;
};
