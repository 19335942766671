import React, { useState } from "react";
import { WardRoundTemplate } from "../../../model/templates/WardRoundTemplate";
import { useAsyncCall } from "react-hook-async-call";
import { useOnMount } from "../../../hooks/useOnMount";
import { getAllWardRoundTemplates } from "../../../services/ward-round-template/getAllWardRoundTemplates";
import { AnimatedButton } from "../../ui/buttons/AnimatedButton";
import AddIcon from "@mui/icons-material/Add";
import { Loading } from "../../ui/loading/Loading";
import { ItemList } from "../../layout/ItemList";
import { WardRoundTemplateDetail } from "./WardRoundTemplateDetail";
import { DeleteWardRoundTemplateDialog } from "./dialogs/DeleteWardRoundTemplateDialog";
import { dummyId } from "../../../model/constants/dummyId";
import { ActionType } from "../../../model/enum/ActionType";
import { WardRoundChallengeType } from "../../../model/enum/Challenge";
import { useParams } from "react-router-dom";

export const WardRoundTemplatesPage = () => {
  const { wardRoundTemplateId } = useParams() as { wardRoundTemplateId: string };
  const [deleteWardRoundDialogOpen, setDeleteWardRoundDialogOpen] = useState<boolean>(false);
  const [selectedWardRound, setSelectedWardRound] = useState<WardRoundTemplate>();
  const [wardRounds, setWardRounds] = useState<WardRoundTemplate[]>([]);

  const [getWardRoundTemplatesCall, , loading] = useAsyncCall(getAllWardRoundTemplates, [], (result) => {
    if (!result.length) return;
    let wardRoundToSelect;
    if (!selectedWardRound || selectedWardRound.id === dummyId) {
      if (wardRoundTemplateId) wardRoundToSelect = result.find((wardRound) => wardRound.id === wardRoundTemplateId);
      else wardRoundToSelect = result[0];
    } else wardRoundToSelect = result.find((wardRound) => wardRound.id === selectedWardRound.id);
    setWardRounds(result);
    setSelectedWardRound(wardRoundToSelect);
  });

  useOnMount(() => {
    getWardRoundTemplatesCall();
  });

  const handleNewWardRoundTemplate = () => {
    const newWardRound: WardRoundTemplate = {
      id: dummyId,
      title: "Nová vizita",
      description: "",
      questionnaires: [],
      challenges: Object.keys(WardRoundChallengeType).map((key) => ({
        type: WardRoundChallengeType[key as keyof typeof WardRoundChallengeType],
        enabled: false,
      })),
      actions: Object.keys(ActionType).map((key) => ({
        id: dummyId,
        type: ActionType[key as keyof typeof ActionType],
        description: "",
        enabled: false,
      })),
    };

    setWardRounds((wardRounds) => [newWardRound, ...wardRounds]);
    setSelectedWardRound(newWardRound);
  };

  const handleCancelNewWardRoundTemplate = () => {
    const updatedWardRounds = wardRounds.filter((round) => round.id !== dummyId);
    setWardRounds(updatedWardRounds);
    setSelectedWardRound(updatedWardRounds[0]);
  };

  const handleSelectedWardRoundUpdate = (updatedWardRound: WardRoundTemplate) => {
    setWardRounds(wardRounds.map((round) => (round.id === selectedWardRound?.id ? updatedWardRound : round)));
    setSelectedWardRound(updatedWardRound);
  };

  const newWardRoundButton = (
    <AnimatedButton
      onClick={handleNewWardRoundTemplate}
      animation={"scale"}
      endIcon={<AddIcon />}
      variant={"contained"}
      disabled={wardRounds.some((round) => round.id === dummyId)}
    >
      Nová vizita
    </AnimatedButton>
  );

  const wardRoundDetail = selectedWardRound ? (
    <WardRoundTemplateDetail
      data={selectedWardRound}
      onDeleteClick={() => setDeleteWardRoundDialogOpen(true)}
      onCancelNewClick={handleCancelNewWardRoundTemplate}
      onSaveSuccess={handleSelectedWardRoundUpdate}
    />
  ) : null;

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <ItemList
        title={"Vizity"}
        items={wardRounds}
        selectedItem={selectedWardRound}
        headerButtons={newWardRoundButton}
        itemDetail={wardRoundDetail}
        onItemClick={setSelectedWardRound}
      />
      {selectedWardRound && (
        <DeleteWardRoundTemplateDialog
          open={deleteWardRoundDialogOpen}
          onClose={() => {
            setDeleteWardRoundDialogOpen(false);
          }}
          onSuccess={(id) => {
            const filteredWardRounds = wardRounds.filter((wardRound) => wardRound.id !== id);
            setWardRounds(filteredWardRounds);
            setSelectedWardRound(filteredWardRounds.length ? filteredWardRounds[0] : undefined);
          }}
          wardRoundTemplateId={selectedWardRound.id}
        />
      )}
    </>
  );
};
