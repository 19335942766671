import { useAppDispatch } from "../redux/hooks";
import { User, userKey } from "../model/User";
import { setUser } from "../redux/auth/authReducer";
import { api, initApiServices } from "../services/api";
import { UserRole } from "../generated";
import { parseUser } from "../parsers/parseUser";
import { useCallback, useMemo } from "react";

export const useUser = () => {
  const dispatch = useAppDispatch();

  const logOut = useCallback(() => {
    localStorage.removeItem(userKey);
    dispatch(setUser(undefined));

    // Re-init api with no Bearer
    api.services = initApiServices();
  }, [dispatch]);

  const setLocalUser = useCallback(
    (user: User) => {
      localStorage.setItem(userKey, JSON.stringify(user));
      dispatch(setUser(user));
    },
    [dispatch]
  );

  const logIn = useCallback(
    async (username: string, password: string): Promise<User> => {
      // TODO: remove this hack when IKEM login works
      const role = username === "admin@mild.blue" ? UserRole.Administrator : UserRole.Doctor;
      const response = await api.services.auth.login({ username, password, role });
      const user = parseUser(response.data);

      setLocalUser(user);

      // Re-init api with new Bearer
      api.services = initApiServices();

      return user;
    },
    [setLocalUser]
  );

  return useMemo(() => {
    return { logOut, logIn, setLocalUser };
  }, [logOut, logIn, setLocalUser]);
};
