import { WardRoundTemplateDtoIn, WardRoundTemplateDtoOut } from "../../generated";
import { WardRoundTemplate } from "../../model/templates/WardRoundTemplate";
import { parseQuestionnaireTemplate } from "./parseQuestionnaireTemplate";
import { fromActionTemplateToGenerated, parseActionTemplate } from "./parseActionTemplate";
import { fromChallengeTemplateToGenerated, parseChallengeTemplate } from "./parseChallengeTemplate";
import { sortActionsByType } from "../../helpers/sortActionsByType";

export const parseWardRoundTemplate = (data: WardRoundTemplateDtoOut): WardRoundTemplate => {
  return {
    id: data.id,
    title: data.name,
    description: data.description,
    questionnaires: data.questionnaires.map(parseQuestionnaireTemplate),
    actions: sortActionsByType(data.actions.map(parseActionTemplate)),
    challenges: data.challenges.map(parseChallengeTemplate),
  };
};

export const fromWardRoundTemplateToGenerated = (data: WardRoundTemplate): WardRoundTemplateDtoIn => {
  return {
    name: data.title,
    description: data.description,
    questionnaires: data.questionnaires.map((q) => q.id),
    actions: data.actions.map(fromActionTemplateToGenerated),
    challenges: data.challenges.map(fromChallengeTemplateToGenerated),
  };
};
