import { AbstractDialog } from "../../../ui/dialogs/AbstractDialog";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAsyncCall } from "react-hook-async-call";
import { useAlerts } from "../../../../hooks/useAlerts";
import { deleteWardRoundTemplate } from "../../../../services/ward-round-template/deleteWardRoundTemplate";

interface Props {
  open: boolean;
  wardRoundTemplateId: string;
  onClose: () => void;
  onSuccess: (wardRoundTemplateId: string) => void;
}

export const DeleteWardRoundTemplateDialog = (props: Props) => {
  const alerts = useAlerts();
  const [deleteWardRoundCall] = useAsyncCall(
    () => deleteWardRoundTemplate(props.wardRoundTemplateId),
    undefined,
    () => {
      alerts.success("Vizita úspešně smazána");
      props.onSuccess(props.wardRoundTemplateId);
      props.onClose();
    },
    () => alerts.error("Při mazání vizity došlo k chybě")
  );

  const actionButtons = (
    <Button color={"error"} endIcon={<DeleteIcon />} onClick={deleteWardRoundCall}>
      Smazat
    </Button>
  );

  return (
    <AbstractDialog open={props.open} onClose={props.onClose} title={"Smazat vizitu"} buttons={actionButtons}>
      Opravdu si přejete vizitu smazat?
    </AbstractDialog>
  );
};
