import { ActionType as ActionTypeGenerated } from "../../generated";
import { ActionType } from "../../model/enum/ActionType";

export const parseActionType = (data: ActionTypeGenerated): ActionType => {
  switch (data) {
    case ActionTypeGenerated.Medicine:
      return ActionType.Medicine;
    case ActionTypeGenerated.Photo:
      return ActionType.Photo;
  }
};
