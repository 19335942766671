import { Answer } from "../../model/questions/Answer";
import { AnswerDtoOut } from "../../generated";
import { parseSeverityStatus } from "../template/parseSeverityStatus";

export const parseAnswer = (data: AnswerDtoOut): Answer => {
  return {
    id: data.id,
    text: data.text,
    severity: parseSeverityStatus(data.severity),
    alertNote: data.alertNote,
  };
};
