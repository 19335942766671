import moment from "moment/moment";

export const formatDateTime = (value: Date, format = "DD.MM.YYYY HH:mm") => {
  return moment(value).format(format);
};

export const formatMinutesToTime = (minutes: number): string => {
  const midnight = moment().startOf("day");
  midnight.add(minutes, "minutes");
  return midnight.format("H[h] mm[m]");
};
