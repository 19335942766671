import { ComponentsOverrides, ComponentsProps, Theme } from "@mui/material";
import { FontWeight } from "../utils";
import { Colors } from "../colors";

declare module "@mui/material/Chip" {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface ChipPropsColorOverrides {
    yellow: true;
    blue: true;
    blueGrey: true;
    green: true;
  }
}

export const MuiChipOverrides: {
  defaultProps?: ComponentsProps["MuiChip"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiChip"];
} = {
  styleOverrides: {
    root: {
      fontWeight: FontWeight.Bold,
      fontSize: 12,
      height: 26,
      "&.MuiChip-filled": {
        padding: 1,
      },
      "&.MuiChip-filledError": {
        color: Colors.error,
        backgroundColor: Colors.errorLightBg,
      },
      "&.MuiChip-outlinedError": {
        color: Colors.error,
        borderColor: Colors.error,
        backgroundColor: Colors.errorLightBg,
      },
      "&.MuiChip-filledWarning": {
        color: Colors.warning,
        backgroundColor: Colors.warningLightBg,
      },
      "&.MuiChip-filledSuccess": {
        color: Colors.success,
        backgroundColor: Colors.successLightBg,
      },
      "&.MuiChip-outlinedSuccess": {
        color: Colors.success,
        borderColor: Colors.success,
        backgroundColor: Colors.successLightBg,
      },
    },
    icon: {
      width: 16,
      marginLeft: 10,
    },
  },
};
