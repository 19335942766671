import { Button, IconButton, Stack } from "@mui/material";
import { ChevronRight, Close, Search } from "@mui/icons-material";
import React from "react";
import { useForm } from "react-hook-form";
import { PatientSearchFormValue } from "../../../model/patient/PatientSearchFormValue";
import { TextInput } from "../../ui/form/inputs/TextInput";
import { Colors } from "../../../theme/colors";

type Props = {
  loading: boolean;
  onSearch: (value: PatientSearchFormValue) => void;
};

export const PatientSearch = (props: Props) => {
  const form = useForm<PatientSearchFormValue>();
  const personalNumber = form.watch("personalNumber");
  const fullName = form.watch("fullName");

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearch = () => {
    props.onSearch(form.getValues());
  };

  return (
    <Stack direction={"row"} gap={2} mb={2} alignItems={"center"}>
      <Search sx={{ color: Colors.grey500 }} />
      <Stack direction={"row"} gap={1} flex={1} alignItems={"flex-end"} onKeyDown={handleKeyDown}>
        <TextInput
          title={"Rodné číslo"}
          form={form}
          name={"personalNumber"}
          disabled={props.loading}
          shrinkTitle
          inputRegex={"[0-9/]"}
        />
        <TextInput title={"Jméno nebo příjmení"} form={form} name={"fullName"} disabled={props.loading} shrinkTitle />
        <IconButton
          onClick={() => {
            form.reset({ personalNumber: "", fullName: "" });
            handleSearch();
          }}
          color={"primary"}
          disabled={!personalNumber && !fullName}
        >
          <Close />
        </IconButton>
        <Button onClick={handleSearch} color={"secondary"} disabled={props.loading} endIcon={<ChevronRight />}>
          Vyhledat pacienty
        </Button>
      </Stack>
    </Stack>
  );
};
