import React, { ReactNode, useState } from "react";
import { DialogContext } from "./DialogContext";

type Props = {
  children: ReactNode;
};

export const DialogProvider = ({ children }: Props) => {
  const [dialogs, setDialogs] = useState<{ [uuid: string]: ReactNode }>({});

  const setDialogByUUID = (uuid: string, dialog: ReactNode) => {
    setDialogs((dialogs) => {
      if (dialog === undefined) {
        if (!dialogs[uuid]) return dialogs; // performance optimization
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [uuid]: _, ...rest } = dialogs;
        return rest;
      }
      return {
        ...dialogs,
        [uuid]: dialog,
      };
    });
  };

  return (
    <DialogContext.Provider value={{ setDialogByUUID }}>
      {children}
      {Object.entries(dialogs).map(([uuid, dialog]) => (
        <React.Fragment key={uuid}>{dialog}</React.Fragment>
      ))}
    </DialogContext.Provider>
  );
};
