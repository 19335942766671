import { QuestionType } from "../../../model/enum/QuestionType";
import { QuestionType as QuestionTypeGenerated } from "../../../generated";

export const fromQuestionTypeToGenerated = (data: QuestionType): QuestionTypeGenerated => {
  switch (data) {
    case QuestionType.FreeTextAnswer:
      return QuestionTypeGenerated.FreeText;
    case QuestionType.SingleChoiceAnswer:
      return QuestionTypeGenerated.SingleChoice;
    case QuestionType.NumberAnswer:
      return QuestionTypeGenerated.Number;
    case QuestionType.MultiChoiceAnswer:
      return QuestionTypeGenerated.MultiChoice;
    case QuestionType.WellBeing:
      return QuestionTypeGenerated.WellBeing;
    case QuestionType.Rating:
      return QuestionTypeGenerated.Rating;
    case QuestionType.BloodPressure:
      return QuestionTypeGenerated.BloodPressure;
    case QuestionType.Information:
      return QuestionTypeGenerated.Information;
  }
};
