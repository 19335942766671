import { ComponentsOverrides, ComponentsProps, Theme } from "@mui/material";
import { Colors } from "../colors";

export const MuiAccordionOverrides: {
  defaultProps?: ComponentsProps["MuiAccordion"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiAccordion"];
} = {
  styleOverrides: {
    root: {
      border: `1px solid ${Colors.grey250}`,
      "&:not(:first-of-type)": {
        borderTop: "none",
      },
      ":before": {
        display: "none",
      },
    },
  },
};
