import { WardRoundOccurrenceDtoOut } from "../../generated";
import { WardRoundOccurrence } from "../../model/ward-round/WardRoundOccurrence";

export const parseWardRoundOccurrence = (data: WardRoundOccurrenceDtoOut): WardRoundOccurrence => {
  return {
    id: data.id,
    occurredAt: new Date(data.occurredAt),
    answeredAt: data.answeredAt ? new Date(data.answeredAt) : undefined,
    isCompleted: data.isCompleted,
    wardRoundName: data.wardRoundName,
    wardRoundId: data.wardRoundId,
  };
};
