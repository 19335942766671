import { Box, Button, Stack } from "@mui/material";
import React, { useState } from "react";
import { ScheduleItem } from "./ScheduleItem";
import { WardRoundEntry } from "../../../../../model/ward-round/WardRoundEntry";
import AddIcon from "@mui/icons-material/Add";
import { Arrow } from "../../../../../assets/Arrow";
import { AddWardRoundToCareDialog } from "./dialogs/AddWardRoundToCareDialog";
import { CareTemplate } from "../../../../../model/templates/CareTemplate";
import { CareType } from "../../../../../model/enum/CareType";
import { ScheduleStartEndItem } from "./ScheduleStartEndItem";
import { NextRoundArrow } from "./NextRoundArrow";
import { Colors } from "../../../../../theme/colors";

interface Props {
  selectedCare: CareTemplate;
  wardRounds: WardRoundEntry[];
  onSaveWardRound: (data: WardRoundEntry, index?: number) => void;
  onDeleteWardRound: (index: number) => void;
}

export const WardRoundsSchedule = (props: Props) => {
  const [wardRoundDetailDialogOpen, setWardRoundDetailDialogOpen] = useState(false);
  const [selectedWardRound, setSelectedWardRound] = useState<WardRoundEntry>();
  const [selectedWardRoundIndex, setSelectedWardRoundIndex] = useState<number>();

  const isPreoperative = props.selectedCare.type === CareType.Preoperative;

  return (
    <>
      <Stack direction={"row"} justifyContent={"flex-start"} flexWrap={"wrap"} alignItems={"center"} gap={2}>
        {!isPreoperative && <ScheduleStartEndItem>Začátek péče</ScheduleStartEndItem>}
        {props.wardRounds.map((wardRound, index) => (
          <ScheduleItem
            key={wardRound.wardRoundTemplate.id + index}
            hideOffset={isPreoperative && index === 0}
            wardRound={wardRound}
            onEdit={() => {
              setSelectedWardRound(wardRound);
              setWardRoundDetailDialogOpen(true);
              setSelectedWardRoundIndex(index);
            }}
          />
        ))}
        {isPreoperative ? (
          <>
            {props.wardRounds.length > 0 && <Box sx={{ background: Colors.grey250 }} width={"20px"} height={"3px"} />}
          </>
        ) : (
          <Arrow size={"sm"} />
        )}
        <Button
          variant={"contained"}
          endIcon={<AddIcon />}
          onClick={() => {
            setSelectedWardRound(undefined);
            setSelectedWardRoundIndex(undefined);
            setWardRoundDetailDialogOpen(true);
          }}
        >
          Přidat vizitu
        </Button>
        {isPreoperative && (
          <>
            <NextRoundArrow days={0} />
            <ScheduleStartEndItem>Zákrok</ScheduleStartEndItem>
          </>
        )}
      </Stack>
      <AddWardRoundToCareDialog
        open={wardRoundDetailDialogOpen}
        onClose={() => {
          setWardRoundDetailDialogOpen(false);
          setSelectedWardRound(undefined);
        }}
        onSaveWardRound={props.onSaveWardRound}
        onDeleteWardRound={props.onDeleteWardRound}
        data={selectedWardRound}
        index={selectedWardRoundIndex}
        selectedCare={props.selectedCare}
        hideOffsetInput={isPreoperative && (selectedWardRoundIndex === 0 || props.wardRounds.length === 0)}
      />
    </>
  );
};
