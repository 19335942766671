import { Colors } from "../../../theme/colors";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { FontFamilyHeadings, FontWeight } from "../../../theme/utils";
import { ArrowBack, Close } from "@mui/icons-material";
import { DialogHeader } from "./parts/DialogHeader";

interface Props {
  open: boolean;
  onClose: (confirmed: boolean) => void;
}

export const SaveWarningDialog = (props: Props) => {
  return (
    <Dialog
      open={props.open}
      fullWidth
      maxWidth={"sm"}
      sx={{ ".MuiDialog-paper": { overflow: "unset", borderRadius: 0 } }}
      onClose={() => props.onClose(false)}
    >
      <DialogHeader>
        <DialogTitle
          sx={{
            margin: 0,
            fontFamily: FontFamilyHeadings,
            fontSize: 16,
            fontWeight: FontWeight.Bold,
          }}
        >
          Zavřít bez uložení?
        </DialogTitle>
      </DialogHeader>

      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "center",
          overflow: "unset",
          p: "15px",
        }}
      >
        <Stack sx={{ width: "100%" }}>
          <Stack gap={1.5}>Pokud zavřete toto okno bez uložení, provedené změny budou ztraceny.</Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ backgroundColor: Colors.grey50, padding: "10px 16px" }}>
        <Button variant={"outlined"} endIcon={<ArrowBack />} onClick={() => props.onClose(false)}>
          Zpět
        </Button>
        <Button
          endIcon={<Close />}
          onClick={() => {
            props.onClose(true);
          }}
        >
          Zavřít
        </Button>
      </DialogActions>
    </Dialog>
  );
};
