import { api } from "../api";
import { QuestionnaireTemplate } from "../../model/templates/QuestionnaireTemplate";
import {
  fromQuestionnaireTemplateToGenerated,
  parseQuestionnaireTemplate,
} from "../../parsers/template/parseQuestionnaireTemplate";

export const postQuestionnaireTemplate = async (questionnaireTemplate: QuestionnaireTemplate) => {
  const result = await api.services.questionnaireTemplates.createQuestionnaireTemplate(
    fromQuestionnaireTemplateToGenerated(questionnaireTemplate)
  );

  return parseQuestionnaireTemplate(result.data);
};
