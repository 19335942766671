import { Menu } from "@mui/material";
import { ReactNode } from "react";
import { Border } from "../../../theme/utils";

type AbstractMenuProps = {
  children: ReactNode;
  anchorEl: null | HTMLElement;
  handleClose: () => void;
  clickableEl: ReactNode;
};

export const AbstractMenu = ({ children, anchorEl, handleClose, clickableEl }: AbstractMenuProps) => {
  return (
    <>
      {clickableEl}
      <Menu
        sx={{
          ".MuiPaper-root": { borderRadius: "0", boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;" },
          ".MuiList-root": { p: 0 },
          ".MuiMenuItem-root": { p: "8px 20px", "&:not(:last-child)": { borderBottom: Border.Grey.Thin } },
        }}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {children}
      </Menu>
    </>
  );
};
