import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack } from "@mui/material";
import { Close } from "@mui/icons-material";
import { ReactNode, useEffect, useState } from "react";
import { Colors } from "../../../theme/colors";
import { FontFamilyHeadings, FontWeight } from "../../../theme/utils";
import { SaveWarningDialog } from "./SaveWarningDialog";
import { DialogHeader } from "./parts/DialogHeader";

interface Props {
  open: boolean;
  onClose: (opened: boolean) => void;
  title: string;
  width?: "xs" | "md" | "lg";
  children?: ReactNode;
  buttons?: ReactNode;
  titleIcon?: ReactNode;
  size?: "xs" | "md";
  alertOnClose?: boolean;
  contentPadding?: string; // override default padding
}

export const AbstractDialog = ({ width = "xs", size = "md", ...props }: Props) => {
  const [warningDialogOpen, setWarningDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!props.open) {
      return;
    }

    // Remove tabindex from dialog container to prevent focus trap when opening other dialogs inside
    setTimeout(() => {
      const el = document.querySelector(".MuiDialog-container") as HTMLElement;
      if (!el) return;

      el.removeAttribute("tabindex");
    });
  }, [props.open]);

  const headerHeight = () => {
    switch (size) {
      case "xs":
        return "38px";
      case "md":
        return "auto";
    }
  };

  const contentPadding = () => {
    if (props.contentPadding) return props.contentPadding;

    switch (size) {
      case "xs":
        return "15px";
      case "md":
        return "20px 30px";
    }
  };

  const handleClose = () => {
    props.alertOnClose ? setWarningDialogOpen(true) : props.onClose(false);
  };

  const onWarningClose = (confirmed: boolean) => {
    setWarningDialogOpen(false);
    if (confirmed) props.onClose(false);
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      fullWidth
      maxWidth={width}
      sx={{ ".MuiDialog-paper": { overflow: "unset", borderRadius: 0 } }}
    >
      <DialogHeader sx={{ backgroundColor: Colors.grey50, height: headerHeight() }}>
        <DialogTitle
          sx={{
            margin: 0,
            fontFamily: FontFamilyHeadings,
            fontSize: 16,
            fontWeight: FontWeight.Bold,
          }}
        >
          <Stack direction={"row"} gap={1} alignItems={"center"}>
            {props.titleIcon}
            {props.title}
          </Stack>
        </DialogTitle>
        <IconButton sx={{ mr: -0.8 }} onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogHeader>
      <DialogContent
        dividers
        sx={{
          display: "flex",
          justifyContent: "center",
          maxHeight: "80vh",
          overflow: "auto",
          p: contentPadding(),
        }}
      >
        <Stack sx={{ width: "100%", height: "100%" }}>
          <Stack gap={1.5}>{props.children}</Stack>
        </Stack>
      </DialogContent>
      {props.buttons && (
        <DialogActions sx={{ backgroundColor: Colors.grey50, padding: "10px 16px" }}>{props.buttons}</DialogActions>
      )}
      {props.alertOnClose && <SaveWarningDialog open={warningDialogOpen} onClose={onWarningClose} />}
    </Dialog>
  );
};
