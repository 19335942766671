import { AnsweredQuestionnaire } from "../../model/questions/AnsweredQuestionnaire";
import { AnsweredQuestionnaireDtoOut } from "../../generated";
import { parseDateOrUndefined } from "../parseDate";
import { parseAnsweredQuestion } from "./parseAnsweredQuestion";

export const parseAnsweredQuestionnaire = (data: AnsweredQuestionnaireDtoOut): AnsweredQuestionnaire => ({
  id: data.id,
  title: data.title,
  questions: data.questions.map(parseAnsweredQuestion),
  answerAt: parseDateOrUndefined(data.answerAt),
});
