import { SelectInputOption } from "../components/ui/form/inputs/props/SelectInputProps";

type EnumType = { [key: string]: string };

export const fromEnumToOptions = (data: EnumType, ignoredValues?: string[]): SelectInputOption<string>[] =>
  Object.values(data)
    .filter((value) => !ignoredValues?.includes(value))
    .map((value: string) => ({
      id: value,
      label: value,
      value,
      key: value,
    }));
