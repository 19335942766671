export const Colors = {
  primary: "#A90E39",
  primary500: "#d31547",
  primary600: "#a50e38",
  primaryLightBg: "#FCF4F7",
  success: "#6FC204",
  successLightBg: "#F3FFE4",
  warning: "#A96500",
  warningLightBg: "#FFECD0",
  errorLightBg: "#FCF4F7",
  error: "#D80606",
  white: "#ffffff",
  black: "#000000",
  grey800: "#323232",
  grey500: "#9e9e9e",
  grey300: "#CACED8",
  grey250: "#E5E5E5",
  grey200: "#ECECEC",
  grey150: "#eeeeee",
  grey100: "#F6F6F6",
  grey50: "#fafafa",
  yellow: "#FFC700",
  yellowLightBg: "#FFECD0",
  blueLightBg: "#D0E9FF",
  blue: "#3D5392",
  blueGrey800: "#737A8E",
  blueGrey700: "#9CA1B0",
  blueGrey600: "#CACED8",
  green: "#6FC204",
  greenDarker: "#5da204",
  divider: "rgba(0, 0, 0, 0.12)",
  darkerAlpha: "rgba(0, 0, 0, 0.7)",
};
