import { AbstractDialog } from "../../../ui/dialogs/AbstractDialog";
import { Button, Typography } from "@mui/material";
import { Patient } from "../../../../model/patient/Patient";
import { PersonRemove } from "@mui/icons-material";

interface Props {
  open: boolean;
  patient: Patient;
  onClose: () => void;
  onSubmit: () => void;
  loading: boolean;
}

export const EndCareDialog = ({ patient, ...props }: Props) => {
  const actionButtons = (
    <Button
      color={"error"}
      endIcon={<PersonRemove />}
      disabled={props.loading}
      onClick={() => {
        props.onSubmit();
      }}
    >
      Ukončit péči
    </Button>
  );

  return (
    <AbstractDialog open={props.open} onClose={props.onClose} title={"Ukončit péči"} buttons={actionButtons}>
      <Typography variant={"body1"}>
        Opravdu si přejete pacientovi{" "}
        <strong>
          {patient.firstName} {patient.lastName}
        </strong>{" "}
        ukončit péči <strong>{patient.care?.name}</strong>?
      </Typography>
      <Typography variant={"body1"}>Tato akce se nedá vrátit zpět.</Typography>
    </AbstractDialog>
  );
};
