import { Stack, StackProps, SxProps } from "@mui/material";
import { ReactNode } from "react";
import { styled } from "@mui/material/styles";
import { Colors } from "../../../../theme/colors";

interface Props {
  children?: ReactNode;
  sx?: SxProps;
}

const StyledHeader = styled(Stack)<StackProps>(() => ({
  flexDirection: "row",
  alignItems: "center",
  padding: "10px 16px",
  ".MuiDialogTitle-root": {
    flexGrow: 1,
    padding: 0,
  },
}));

export const DialogHeader = ({ children, ...props }: Props) => {
  return (
    <StyledHeader sx={{ backgroundColor: Colors.grey50 }} {...props}>
      {children}
    </StyledHeader>
  );
};
