import FeedbackIcon from "@mui/icons-material/Feedback";
import { Colors } from "../../../../theme/colors";
import { FeedbackOutlined } from "@mui/icons-material";

interface Props {
  isWarning: boolean;
}

export const AlertNoteIcon = ({ isWarning }: Props) => {
  if (isWarning) return <FeedbackIcon sx={{ color: Colors.yellow }} />;
  return <FeedbackOutlined sx={{ color: Colors.grey500 }} />;
};
