import { FormHelperText as MuiFormHelperText, Stack } from "@mui/material";
import { Colors } from "../../../theme/colors";
import { ReactNode } from "react";
import { DeepRequired, FieldError, FieldErrorsImpl, Merge } from "react-hook-form";

interface Props {
  color?: string;
  children: ReactNode;
  icon?: ReactNode;
  fieldError?: FieldError | Merge<FieldError, FieldErrorsImpl<DeepRequired<Date>>>;
}

export const FormHelperText = ({ color = Colors.black, ...props }: Props) => {
  if (props.fieldError) {
    return null;
  }

  return (
    <Stack direction={"row"} gap={0.2} sx={{ color, alignItems: "center" }}>
      {props.icon && props.children && (
        <Stack
          sx={{
            ".MuiSvgIcon-root": {
              fontSize: "1rem",
              marginTop: "3px",
            },
          }}
        >
          {props.icon}
        </Stack>
      )}
      <MuiFormHelperText sx={{ color }}>{props.children}</MuiFormHelperText>
    </Stack>
  );
};
