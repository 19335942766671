import { Alert, Button, Divider, Stack, Typography } from "@mui/material";
import { Patient } from "../../../model/patient/Patient";
import React, { useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { AbstractDialog } from "../dialogs/AbstractDialog";

interface Props {
  patientData?: Patient;
}

export const WarningCard = ({ patientData }: Props) => {
  const [dialogOpened, setDialogOpened] = useState(false);

  const isCritical = patientData?.critical ?? false;
  const anySeverity =
    patientData?.severityDetail?.criticalAnswers?.length ||
    patientData?.severityDetail?.messages?.length ||
    patientData?.severityDetail?.reasons?.length;

  if ((!isCritical && !anySeverity) || patientData == undefined) {
    return null;
  }

  const messages = patientData.severityDetail && (
    <Stack divider={<Divider />} gap={2}>
      <Stack gap={1}>
        {patientData.severityDetail.messages.map((message, key) => (
          <Alert severity={"warning"} key={key}>
            {message.replaceAll('"', "")}
          </Alert>
        ))}
      </Stack>

      {(() => {
        const criticalAnswers = patientData?.severityDetail?.criticalAnswers ?? [];
        if (!criticalAnswers.length) {
          return null;
        }

        const answeredQuestions = criticalAnswers
          .map((a) => a.answeredQuestions)
          .reduce((prev, next) => prev.concat(next));

        return (
          <Stack gap={2}>
            <Typography variant={"h4"} m={0}>
              Kritické odpovědi pacienta
            </Typography>
            {answeredQuestions.length > 0 && (
              <Stack gap={1}>
                {answeredQuestions.map((answeredQuestion) => {
                  return (
                    <Stack key={answeredQuestion.question.id} direction={"row"} gap={1}>
                      <i>{answeredQuestion.question.text}</i>
                      <strong>- {answeredQuestion.userAnswers.join(", ")}</strong>
                    </Stack>
                  );
                })}
              </Stack>
            )}
          </Stack>
        );
      })()}
    </Stack>
  );

  return (
    <Stack minWidth={260}>
      <Alert
        severity="error"
        action={
          <Button onClick={() => setDialogOpened(true)} color="error" size="small" endIcon={<ArrowForwardIcon />}>
            Podrobnosti
          </Button>
        }
      >
        Pacient byl označen za kritického
      </Alert>
      <AbstractDialog open={dialogOpened} onClose={setDialogOpened} title={"Podrobnosti"} width={"md"}>
        {messages}
      </AbstractDialog>
    </Stack>
  );
};
