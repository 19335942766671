import { Stack } from "@mui/material";
import SideBarMenu from "./components/layout/SideBarMenu";
import { Route, Routes, useNavigate } from "react-router-dom";
import { PatientsPage } from "./components/pages/patients/PatientsPage";
import { Colors } from "./theme/colors";
import { PatientDetailPage } from "./components/pages/patient-detail/PatientDetailPage";
import { LoginPage } from "./components/pages/LoginPage";
import { selectUser } from "./redux/auth/authSelectors";
import { useEffect, useState } from "react";
import { NavigationRoutes } from "./model/constants/NavigationRoutes";
import { useAppSelector } from "./redux/hooks";
import { useUser } from "./hooks/useUser";
import { getLocalUser } from "./services/auth/getLocalUser";
import { isTokenValid } from "./services/auth/isTokenValid";
import { useOnMount } from "./hooks/useOnMount";
import { PatientStatistics } from "./components/pages/statistics/PatientStatistics";
import { QuestionnaireTemplatesPage } from "./components/pages/questionnaires-templates/QuestionnaireTemplatesPage";
import { PagePadding } from "./theme/utils";
import { WardRoundTemplatesPage } from "./components/pages/ward-round-templates/WardRoundTemplatesPage";
import { CareTemplatesPage } from "./components/pages/care-templates/CareTemplatesPage";
import { TagCategoriesPage } from "./components/pages/tags/TagCategoriesPage";
import { OrganisationContext } from "./context/OrganisationContext";
import { Organisation } from "./model/Organisation";
import { useAsyncCall } from "react-hook-async-call";
import { getConfiguration } from "./services/config/getConfiguration";
import { getApiUrl } from "./helpers/getApiUrl";
import { LicenseInfo } from "@mui/x-license";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENCE_KEY ?? "");

export const AppWrapper = () => {
  const navigate = useNavigate();
  const user = useAppSelector(selectUser);
  const { logOut, setLocalUser } = useUser();

  // Organisation
  const [organisation, setOrganisation] = useState<Organisation>();
  const [getCurrentOrganisation] = useAsyncCall(getConfiguration, undefined, (config) => {
    console.log(config);
    console.log(
      config.organisations.find(
        (organisation) =>
          organisation.config.doctorAdminUrl === window.origin || organisation.config.apiUrl === getApiUrl()
      )
    );
    setOrganisation(
      config.organisations.find(
        (organisation) =>
          organisation.config.doctorAdminUrl === window.origin || organisation.config.apiUrl === getApiUrl()
      )
    );
  });

  useEffect(() => {
    if (!user) {
      navigate(NavigationRoutes.login.getLink());
    }
  }, [user, navigate]);

  // Load locally stored values into global state
  useOnMount(() => {
    getCurrentOrganisation();

    const storedUser = getLocalUser();

    if (storedUser && isTokenValid(storedUser)) {
      setLocalUser(storedUser);
    } else {
      logOut();
      return;
    }
  });

  return (
    <OrganisationContext.Provider value={{ organisation, setOrganisation }}>
      <Stack direction={"row"}>
        {user && <SideBarMenu />}
        <Stack
          sx={{
            height: "100vh",
            width: "100%",
            overflowY: "auto",
            backgroundColor: Colors.grey50,
            ...PagePadding,
          }}
        >
          <Routes>
            <Route path={"/login"} element={<LoginPage />} />
            <Route path={"/patients"} element={<PatientsPage />} />
            <Route path={"/patients/:patientId"} element={<PatientDetailPage />} />
            <Route path={"/patients/:patientId/statistics"} element={<PatientStatistics />} />
            <Route path={"/questionnaire-templates"} element={<QuestionnaireTemplatesPage />} />
            <Route path={"/tags"} element={<TagCategoriesPage />} />
            {["/ward-round-templates", "/ward-round-templates/:wardRoundTemplateId"].map((path) => (
              <Route path={path} element={<WardRoundTemplatesPage />} key={path} />
            ))}
            <Route path={"/care-templates"} element={<CareTemplatesPage />} />
            <Route path={"*"} element={<PatientsPage />} />
          </Routes>
        </Stack>
      </Stack>
    </OrganisationContext.Provider>
  );
};
