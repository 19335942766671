import { FieldValues, UseFormReturn } from "react-hook-form";

// uesForm isValid property is not working properly mainly with forms that contain dynamic inputs
export const isFormValid = <TFormValues extends FieldValues>(form: UseFormReturn<TFormValues>): boolean => {
  return !Object.keys(form.formState.errors).length;
};

export const isFormDirty = <TFormValues extends FieldValues>(form: UseFormReturn<TFormValues>): boolean => {
  return !!Object.keys(form.formState.dirtyFields).length;
};
