import { PatientCare } from "../../../model/patient/PatientCare";
import { Alert, Stack, Tab, Tabs } from "@mui/material";
import { TabPanel } from "../../ui/tabs/TabPanel";
import { PatientDetailWardRounds } from "./tabs/ward-rounds/PatientDetailWardRounds";
import { PatientDetailVitality } from "./tabs/vitality/PatientDetailVitality";
import React, { useEffect, useState } from "react";
import { useBreakpointDown } from "../../../hooks/useBreakpointDown";
import { AlarmOnRounded } from "@mui/icons-material";
import { formatDateTime } from "../../../helpers/formatDateTime";
import { PatientStatistics } from "./tabs/PatientStatistics";

type Props = {
  care: PatientCare;
};

enum CareTabs {
  WardRounds,
  Statistics,
  Vitality,
}

export const PatientCareDetail = ({ care }: Props) => {
  const [showTabs, setShowTabs] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<CareTabs>(CareTabs.WardRounds);
  const isMobile = useBreakpointDown("sm");

  useEffect(() => {
    setShowTabs(care.isActive);
  }, [care]);

  return (
    <Stack>
      {showTabs && (
        <Tabs
          value={activeTab}
          onChange={(_, activeTab) => setActiveTab(activeTab)}
          orientation={isMobile ? "vertical" : "horizontal"}
          sx={
            isMobile
              ? {
                  ".MuiTab-root": {
                    alignItems: "flex-start",
                  },
                  ".MuiTabs-indicator": {
                    left: 0,
                  },
                }
              : undefined
          }
        >
          <Tab label={"Vizity"} />
          <Tab label={"Statistiky"} />
          <Tab label={"Dnešní vitalita"} />
        </Tabs>
      )}
      <TabPanel activeTab={activeTab} index={CareTabs.WardRounds}>
        {care.nextWardRoundDate && (
          <Alert color={"success"} icon={<AlarmOnRounded />} sx={{ mb: 3 }}>
            Další vizita je naplánovaná na <strong>{formatDateTime(care.nextWardRoundDate, "D.M. HH:mm")}</strong>
          </Alert>
        )}
        <PatientDetailWardRounds careId={care.id} />
      </TabPanel>
      <TabPanel activeTab={activeTab} index={CareTabs.Statistics}>
        <PatientStatistics />
      </TabPanel>
      <TabPanel activeTab={activeTab} index={CareTabs.Vitality}>
        <PatientDetailVitality />
      </TabPanel>
    </Stack>
  );
};
