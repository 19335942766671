import { ComponentsOverrides, ComponentsProps, Theme } from "@mui/material";
import { FontFamilyHeadings, FontWeight } from "../utils";
import { Colors } from "../colors";

export const MuiTabOverrides: {
  defaultProps?: ComponentsProps["MuiTab"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiTab"];
} = {
  styleOverrides: {
    root: {
      fontWeight: FontWeight.Bold,
      fontFamily: FontFamilyHeadings,
      fontSize: 16,
      textTransform: "none",
      padding: "5px 15px",
      minHeight: 0,
      color: Colors.grey500,
    },
  },
};
