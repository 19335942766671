import { DialogProps } from "../../../ui/dialogs/props/DialogProps";
import { AbstractDialog } from "../../../ui/dialogs/AbstractDialog";
import { Button } from "@mui/material";
import { useAsyncCall } from "react-hook-async-call";
import { useForm } from "react-hook-form";
import { TextInput } from "../../../ui/form/inputs/TextInput";
import AddIcon from "@mui/icons-material/Add";
import React, { useEffect } from "react";
import { useAlerts } from "../../../../hooks/useAlerts";
import { TagCategory } from "../../../../model/tag/TagCategory";
import { postTagCategory } from "../../../../services/tags/postTagCategory";
import { putTagCategory } from "../../../../services/tags/putTagCategory";

type Props = DialogProps & {
  tagCategory?: TagCategory;
  onSuccess: (tagCategory: TagCategory) => void;
};

export const TagCategoryDialog = ({ tagCategory, ...props }: Props) => {
  const alerts = useAlerts();
  const form = useForm<{ title: string }>();
  const title = form.watch("title");

  const [addTagCategory, , loadingAdd] = useAsyncCall(
    postTagCategory,
    undefined,
    (tagCategory) => {
      alerts.success("Kategorie štítků byla úspěšně přidána");
      props.onClose();
      props.onSuccess(tagCategory);
    },
    alerts.error
  );
  const [editTagCategory, , loadingEdit] = useAsyncCall(
    putTagCategory,
    undefined,
    (tagCategory) => {
      alerts.success("Kategorie štítků byla úspěšně uložena");
      props.onClose();
      props.onSuccess(tagCategory);
    },
    alerts.error
  );

  useEffect(() => {
    if (tagCategory) {
      form.setValue("title", tagCategory.title);
    }
  }, [form, tagCategory]);

  return (
    <AbstractDialog
      open={props.open}
      onClose={props.onClose}
      width={"md"}
      title={tagCategory ? "Upravit kategorii štítků" : "Přidat kategorii štítků"}
      buttons={
        <Button
          onClick={() => (tagCategory ? editTagCategory(tagCategory.id, title) : addTagCategory(title))}
          endIcon={tagCategory ? undefined : <AddIcon />}
          disabled={loadingAdd || loadingEdit || !form.formState.isValid}
        >
          {tagCategory ? "Uložit" : "Přidat"}
        </Button>
      }
    >
      <TextInput title={"Název"} name={"title"} form={form} required />
    </AbstractDialog>
  );
};
