import { api } from "../api";
import { QuestionTemplate } from "../../model/templates/QuestionTemplate";
import { parseQuestionTemplate } from "../../parsers/template/parseQuestionTemplate";
import { fromQuestionTemplateToGenerated } from "../../parsers/template/to-generated/fromQuestionTemplateToGenerated";

export const postQuestionTemplate = async (questionTemplate: QuestionTemplate): Promise<QuestionTemplate> => {
  const result = await api.services.questionTemplate.createQuestionTemplate(
    fromQuestionTemplateToGenerated(questionTemplate)
  );
  return parseQuestionTemplate(result.data);
};
