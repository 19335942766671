import { api } from "../api";
import { QuestionnaireTemplate } from "../../model/templates/QuestionnaireTemplate";
import {
  fromQuestionnaireTemplateToGenerated,
  parseQuestionnaireTemplate,
} from "../../parsers/template/parseQuestionnaireTemplate";

export const putQuestionnaireTemplate = async (
  questionnaireTemplateId: string,
  questionnaireTemplate: QuestionnaireTemplate
) => {
  const result = await api.services.questionnaireTemplates.updateQuestionnaireTemplate(
    questionnaireTemplateId,
    fromQuestionnaireTemplateToGenerated(questionnaireTemplate)
  );
  return parseQuestionnaireTemplate(result.data);
};
