import {
  ActionDtoOut,
  ActionDtoOutUserAnswer,
  ActionType as ActionTypeGenerated,
  MedicineActionResponseDtoOut,
  PhotoActionResponseDtoOut,
} from "../../generated";
import { ActionResponse, ActionResponseValue } from "../../model/actions/ActionResponse";
import { parseActionType } from "./parseActionType";
import { parseDate, parseDateOrUndefined } from "../parseDate";
import { ActionType } from "../../model/enum/ActionType";

export const parseActionResponse = (data: ActionDtoOut): ActionResponse => {
  const type = parseActionType(data.type);
  return {
    type,
    id: data.id,
    text: data.text,
    description: data.description,
    createdAt: parseDate(data.createdAt),
    value: data.userAnswer && parseActionResponseValue(data.userAnswer),
  };
};

const parseActionResponseValue = (data: ActionDtoOutUserAnswer): ActionResponseValue | undefined => {
  switch (data.type) {
    case ActionTypeGenerated.Medicine:
      return {
        type: ActionType.Medicine,
        takenAt: parseDateOrUndefined((data as MedicineActionResponseDtoOut).takenAt),
      };
    case ActionTypeGenerated.Photo:
      return {
        type: ActionType.Photo,
        fileId: (data as PhotoActionResponseDtoOut).fileId,
        fileName: (data as PhotoActionResponseDtoOut).fileName,
        content: (data as PhotoActionResponseDtoOut).content,
      };
  }
};
