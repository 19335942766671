import { CircularProgress, Stack } from "@mui/material";

interface Props {
  size?: number;
}

export const Loading = ({ size = 40 }: Props) => {
  return (
    <Stack justifyContent={"center"} alignItems={"center"} width={"100%"} height={"100%"}>
      <CircularProgress size={size} />
    </Stack>
  );
};
