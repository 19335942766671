import { BloodPressureProgressSummaryDtoOut, MinProgressSummaryDtoOut } from "../../generated";
import { BarChartData } from "../../model/statistics/BarChart";
import moment from "moment";
import { BloodPressureBarChartData } from "../../model/challenge/BloodPressureValue";
import { sortByDate } from "../../helpers/sortByDate";

export const parseStatisticsForBarChart = (
  data: MinProgressSummaryDtoOut,
  since: string,
  until: string
): BarChartData[] => {
  let barChartData = data.progress.map((dailyProgress): BarChartData => {
    return {
      date: new Date(dailyProgress.timestamp),
      value: dailyProgress.value,
      goalMet: dailyProgress.value >= data.minGoal,
    };
  });
  barChartData = sortByDate(barChartData);
  return fillSpacesBetweenDates(barChartData, since, until);
};

export const fillSpacesBetweenDates = (items: BarChartData[], since: string, until: string): BarChartData[] => {
  const checkedMoment = moment(since);
  const difference = moment(until).diff(moment(since), "days");

  for (let i = 0; i < difference; i++) {
    checkedMoment.add(1, "days");
    const sameDayItemExists = items.some((item) => moment(item.date).isSame(checkedMoment, "days"));
    if (!sameDayItemExists) {
      items.push({
        goalMet: false,
        value: 0,
        date: checkedMoment.toDate(),
      });
    }
  }
  return sortByDate(items);
};

export const parseBloodPressureStatistics = (
  data: BloodPressureProgressSummaryDtoOut,
  since: string,
  until: string
): BloodPressureBarChartData => {
  return {
    systolic: parseStatisticsForBarChart(
      {
        ...data,
        minGoal: data.minGoal.systolic,
        progress: data.progress.map((item) => ({ ...item, value: item.value.systolic })),
      },
      since,
      until
    ),
    diastolic: parseStatisticsForBarChart(
      {
        ...data,
        minGoal: data.minGoal.diastolic,
        progress: data.progress.map((item) => ({ ...item, value: item.value.diastolic })),
      },
      since,
      until
    ),
  };
};
