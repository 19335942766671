import { GoalType } from "../../generated";
import { ChallengeType } from "../../model/enum/Challenge";

export const parseChallengeType = (data: GoalType): ChallengeType => {
  switch (data) {
    case GoalType.HeartRate:
      return ChallengeType.HeartRate;
    case GoalType.Pressure:
      return ChallengeType.Pressure;
    case GoalType.Sleep:
      return ChallengeType.Sleep;
    case GoalType.Steps:
      return ChallengeType.Steps;
    case GoalType.Water:
      return ChallengeType.Water;
  }
};
