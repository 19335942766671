import { Chip, FormControl, MenuItem, Stack, TextField } from "@mui/material";
import { useAlerts } from "../../../../hooks/useAlerts";
import { Controller, FieldValues } from "react-hook-form";
import { SelectInputProps } from "./props/SelectInputProps";
import { Colors } from "../../../../theme/colors";
import { InputWrapper } from "./InputWrapper";

export const SelectInput = <TFormValues extends FieldValues, OptionValueType extends NonNullable<unknown>>({
  name,
  title,
  disabled = false,
  form,
  options,
  required = false,
  onChange,
  helperText,
  fieldError,
  optionsValueComparator = (a, b) => a === b,
}: SelectInputProps<TFormValues, OptionValueType>) => {
  const alerts = useAlerts();

  const keyToValue = (key: string): OptionValueType | null | undefined => {
    const option = options.find((option) => option.key === key);
    if (option !== undefined) {
      return option.value;
    } else {
      alerts.log(`Form select input ${key} has key that was not found in the select options`, key);
      return undefined;
    }
  };

  const valueToKey = (value: OptionValueType): string => {
    const option = options.find((option) => optionsValueComparator(option.value, value));
    if (option !== undefined) {
      return option.key;
    } else {
      return "";
    }
  };

  const register = form.register(name, { required: required && `Pole ${title?.toLowerCase()} nesmí být prázdné` });

  return (
    <InputWrapper title={title} required={required}>
      <FormControl>
        <Controller
          name={register.name}
          control={form.control}
          render={({ field }) => (
            <TextField
              {...field}
              sx={{ background: Colors.white }}
              select
              size="small"
              onChange={(e) => {
                const value = keyToValue(e.target.value) ?? undefined;
                field.onChange(value);
                onChange?.();
              }}
              value={valueToKey(field.value)}
              disabled={disabled}
              required={required}
              error={!!fieldError}
              helperText={fieldError?.message ?? helperText}
            >
              {options.map((option) => (
                <MenuItem key={option.key} value={option.key}>
                  <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between" flex={1}>
                    {option.label}
                    {option.chip && <Chip label={option.chip} size={"small"} />}
                  </Stack>
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </FormControl>
    </InputWrapper>
  );
};
