import { SeverityStatus as SeverityStatusGenerated } from "../../generated";
import { SeverityStatus } from "../../model/enum/SeverityStatus";

export const parseSeverityStatus = (data: SeverityStatusGenerated): SeverityStatus => {
  switch (data) {
    case SeverityStatusGenerated.High:
    case SeverityStatusGenerated.Low:
      return SeverityStatus.High;
    case SeverityStatusGenerated.No:
      return SeverityStatus.No;
  }
};

export const fromSeverityStatusToGenerated = (data: SeverityStatus): SeverityStatusGenerated => {
  switch (data) {
    case SeverityStatus.High:
      return SeverityStatusGenerated.High;
    case SeverityStatus.No:
      return SeverityStatusGenerated.No;
  }
};
