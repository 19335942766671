import { Stack } from "@mui/material";
import {
  CareTemplateBloodPressureChallenge,
  CareTemplateChallenge,
  CareTemplateNumericChallenge,
} from "../../../../../model/templates/CareTemplateChallenge";
import { ChallengeType } from "../../../../../model/enum/Challenge";
import { StepsChallenge } from "./challenges/StepsChallenge";
import { UseFormReturn } from "react-hook-form";
import { HeartRateChallenge } from "./challenges/HeartRateChallenge";
import { PressureChallenge } from "./challenges/PressureChallenge";
import { SleepChallenge } from "./challenges/SleepChallenge";
import { WaterChallenge } from "./challenges/WaterChallenge";

interface Props {
  form: UseFormReturn<CareTemplateChallenge[]>;
  challenges: CareTemplateChallenge[];
}

export const CareTemplateVitality = ({ form, ...props }: Props) => {
  return (
    <Stack gap={2}>
      {props.challenges.map((challenge, index) => {
        switch (challenge.type) {
          case ChallengeType.Steps:
            return (
              <StepsChallenge
                key={challenge.id}
                form={form as UseFormReturn<CareTemplateNumericChallenge[]>}
                index={index}
                data={challenge as CareTemplateNumericChallenge}
              />
            );
          case ChallengeType.HeartRate:
            return (
              <HeartRateChallenge
                key={challenge.id}
                form={form as UseFormReturn<CareTemplateNumericChallenge[]>}
                index={index}
                data={challenge as CareTemplateNumericChallenge}
              />
            );
          case ChallengeType.Pressure:
            return (
              <PressureChallenge
                key={challenge.id}
                form={form as UseFormReturn<CareTemplateBloodPressureChallenge[]>}
                index={index}
                data={challenge as CareTemplateBloodPressureChallenge}
              />
            );
          case ChallengeType.Sleep:
            return (
              <SleepChallenge
                key={challenge.id}
                form={form as UseFormReturn<CareTemplateNumericChallenge[]>}
                index={index}
                data={challenge as CareTemplateNumericChallenge}
              />
            );
          case ChallengeType.Water:
            return (
              <WaterChallenge
                key={challenge.id}
                form={form as UseFormReturn<CareTemplateNumericChallenge[]>}
                index={index}
                data={challenge as CareTemplateNumericChallenge}
              />
            );
        }
      })}
    </Stack>
  );
};
