import { RepetitionGranularity as RepetitionGranularityGenerated } from "../generated";
import { RepetitionGranularity } from "../model/enum/RepetitionGranularity";

export const parseRepetitionGranularity = (data: RepetitionGranularityGenerated): RepetitionGranularity => {
  switch (data) {
    case RepetitionGranularityGenerated.Minute:
      return RepetitionGranularity.Minute;
    case RepetitionGranularityGenerated.Hour:
      return RepetitionGranularity.Hour;
    case RepetitionGranularityGenerated.Day:
      return RepetitionGranularity.Day;
    case RepetitionGranularityGenerated.Week:
      return RepetitionGranularity.Week;
    case RepetitionGranularityGenerated.Month:
      return RepetitionGranularity.Month;
  }
};
export const fromRepetitionGranularityToGenerated = (data: RepetitionGranularity): RepetitionGranularityGenerated => {
  switch (data) {
    case RepetitionGranularity.Minute:
      return RepetitionGranularityGenerated.Minute;
    case RepetitionGranularity.Hour:
      return RepetitionGranularityGenerated.Hour;
    case RepetitionGranularity.Day:
      return RepetitionGranularityGenerated.Day;
    case RepetitionGranularity.Week:
      return RepetitionGranularityGenerated.Week;
    case RepetitionGranularity.Month:
      return RepetitionGranularityGenerated.Month;
  }
};
