import { WardRoundEntry } from "../../model/ward-round/WardRoundEntry";
import { WardRoundEntryDtoOut } from "../../generated";
import { parseWardRoundTemplate } from "../template/parseWardRoundTemplate";

import { parseRepetitionGranularity } from "../parseRepetitionGranularity";

export const parseWardRoundEntry = (data: WardRoundEntryDtoOut): WardRoundEntry => ({
  wardRoundTemplate: parseWardRoundTemplate(data.wardRoundTemplate),
  offsetDays: data.offsetDays,
  isRecurring: data.isRecurring,
  repetitionGranularity: data.repetitionGranularity && parseRepetitionGranularity(data.repetitionGranularity),
  repetitionValue: data.repetitionValue,
  numberOfOccurrences: data.numberOfRepetitions,
  start: data.start,
});
