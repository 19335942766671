import { AbstractMenu } from "./AbstractMenu";
import { IconButton } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { MouseEvent, ReactNode, useState } from "react";

interface IconMenuProps {
  customIcon?: ReactNode;
  children: ReactNode;
}

export const IconMenu = ({ customIcon, children }: IconMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <AbstractMenu
      anchorEl={anchorEl}
      handleClose={() => setAnchorEl(null)}
      clickableEl={<IconButton onClick={handleClick}>{customIcon ?? <MoreVert />}</IconButton>}
    >
      {children}
    </AbstractMenu>
  );
};
