import { Organisation } from "../model/Organisation";
import { createContext } from "react";

type OrganisationContextData = {
  organisation?: Organisation;
  setOrganisation: (data?: Organisation) => void;
};

export const OrganisationContext = createContext<OrganisationContextData>({
  organisation: undefined,
  setOrganisation: () => {
    return;
  },
});
