import { Card } from "../../../ui/cards/Card";
import { Stack } from "@mui/material";
import { Colors } from "../../../../theme/colors";
import { useState } from "react";
import { QuestionType } from "../../../../model/enum/QuestionType";
import { QuestionIcon } from "../icons/QuestionIcon";
import { QuestionTemplateDetailDialog } from "../dialogs/QuestionTemplateDetailDialog";
import { QuestionnaireTemplate } from "../../../../model/templates/QuestionnaireTemplate";

interface Props {
  questionType: QuestionType;
  questionnaire: QuestionnaireTemplate;
  onSubmit: (questionnaireTemplate: QuestionnaireTemplate) => void;
}

export const NewQuestionCard = (props: Props) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  return (
    <>
      <Card onClick={() => setDialogOpen(true)} sx={{ maxWidth: 250, width: "100%" }}>
        <Stack gap={0.5} alignItems={"center"}>
          <Stack
            bgcolor={Colors.grey50}
            p={0.5}
            width={"40px"}
            height={"40px"}
            borderRadius={"20px"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{
              ".MuiSvgIcon-root": {
                fontSize: "24px",
                color: Colors.blueGrey700,
              },
            }}
          >
            <QuestionIcon questionType={props.questionType} />
          </Stack>
          <strong>{props.questionType}</strong>
        </Stack>
      </Card>
      <QuestionTemplateDetailDialog
        questionnaire={props.questionnaire}
        questionType={props.questionType}
        onSubmit={props.onSubmit}
        dialogOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
    </>
  );
};
