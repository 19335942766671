import { ReactNode } from "react";
import { ListItemIcon, MenuItem, SxProps } from "@mui/material";

type ItemProps = {
  title: string;
  icon?: ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  sx?: SxProps;
};

export const Item = ({ title, icon, ...props }: ItemProps) => {
  return (
    <MenuItem {...props}>
      {icon ? <ListItemIcon>{icon}</ListItemIcon> : undefined}
      {title}
    </MenuItem>
  );
};
