import { Stack } from "@mui/material";
import { Colors } from "../../../../../theme/colors";
import { FontWeight } from "../../../../../theme/utils";
import { ReactNode } from "react";
import { Card } from "../../../../ui/cards/Card";
import { BloodPressureValue, isBloodPressureValue } from "../../../../../model/challenge/BloodPressureValue";
import { ChallengeType } from "../../../../../model/enum/Challenge";
import { formatTime } from "../../../../../helpers/formatTime";
import { addSpacesToNumber } from "../../../../../helpers/addSpacesToNumber";
import { StatsCardGoal } from "./StatsCardGoal";
import { WarningAmberOutlined } from "@mui/icons-material";

interface Props {
  children?: ReactNode;
  buttons?: ReactNode;
  type?: string;
  date?: string;
  value?: number | BloodPressureValue;
  minGoal?: number | BloodPressureValue;
  maxGoal?: number | BloodPressureValue;
}

export const StatsCard = (props: Props) => {
  const noData = props.value === undefined;

  return (
    <Card>
      <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
        <Stack>
          <Stack sx={{ color: Colors.primary, fontWeight: FontWeight.Bold }}>{props.type}</Stack>
          <Stack sx={{ color: Colors.grey500, fontSize: 12 }}>{props.date}</Stack>
        </Stack>
        <Stack alignItems={"end"}>
          {noData ? (
            <Stack direction={"column"} alignItems={"flex-end"} color={Colors.grey500}>
              <Stack direction={"row"} alignItems={"center"} gap={0.5} color={Colors.warning}>
                <WarningAmberOutlined sx={{ fontSize: 12 }} />
                <Stack sx={{ fontSize: 12 }}>Žádná data</Stack>
              </Stack>
              <StatsCardGoal type={props.type} value={props.value} minGoal={props.minGoal} maxGoal={props.maxGoal} />
            </Stack>
          ) : (
            <>
              <Stack sx={{ color: Colors.black, fontWeight: FontWeight.Bold }}>
                {(() => {
                  if (isBloodPressureValue(props.value)) {
                    return `${props.value.systolic}/${props.value.diastolic}`;
                  } else if (props.type === ChallengeType.Sleep && props.value !== undefined) {
                    return formatTime(props.value);
                  }

                  return props.value ? addSpacesToNumber(props.value) : 0;
                })()}
              </Stack>
              <StatsCardGoal type={props.type} value={props.value} minGoal={props.minGoal} maxGoal={props.maxGoal} />
            </>
          )}
        </Stack>
      </Stack>
      {props.buttons && <Stack gap={1}>{props.buttons}</Stack>}
    </Card>
  );
};
